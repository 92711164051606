import { AbstractControl, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { InputCustomerEmailsValidation, InputCustomerPhonesValidation } from './input-customer-emails-and-phones.model';


export class InputCustomerEmailsAndPhonesValidators {
  static requiredEmails(control: AbstractControl): ValidationErrors | null {
    let required = { required: true };

    const value: InputCustomerEmailsValidation[] = control.value;
    if (!value) {
      return required;
    }

    // Check each items in given service list, allow if each rows do not empty

    const allValid = value.map(item => {
      const isEmailTypeIdValid = !!item.emailTypeId;

      const mailNameControl = new FormControl(item.mailName, Validators.email);
      const isMailNameValid = !!item.mailName && mailNameControl.valid;

      return isEmailTypeIdValid && isMailNameValid;
    }).every(isValid => true === isValid);

    if (allValid) {
      required = null;
    }

    return required;
  }

  static requiredPhones(control: AbstractControl): ValidationErrors | null {
    let required = { required: true };

    const value: InputCustomerPhonesValidation[] = control.value;
    if (!value) {
      return required;
    }

    // Check each items in given service list, allow if each rows do not empty

    const allValid = value.map(item => [
      !item.country,
      !item.phoneNumber,
      !item.phoneTypeId,
    ].every(isEmpty => false === isEmpty)
    ).every(isValid => true === isValid);

    if (allValid) {
      required = null;
    }

    return required;
  }
}
