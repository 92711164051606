import { HttpContextToken } from '@angular/common/http';

export const IS_REQUEST_BEFORE_LOGOUT = new HttpContextToken<boolean>(() => false);

export enum Permission {
  ACCESS_ALL = 32767,

  ACCOUNT_DELETE = 16,
  ACCOUNT_FILTER_SALES_ORGANIZATION = 1004,
  ACCOUNT_GET = 13,
  ACCOUNT_INSERT = 14,
  ACCOUNT_LIST = 11,
  ACCOUNT_SEARCH = 12,
  ACCOUNT_UPDATE = 15,
  ACCOUNT_UPDATE_SALES_ROUTE = 1011,
  ACCOUNT_PRODUCTION_ACCOUNT_CODE = 18,
  ACCOUNT_UPDATE_NAME = 27,
  ACCOUNT_LIST_DOWNLOAD = 29,

  ACTIVITY_DELETE = 46,
  ACTIVITY_GET = 43,
  ACTIVITY_INSERT = 44,
  ACTIVITY_LIST = 41,
  ACTIVITY_SEARCH = 42,
  ACTIVITY_UPDATE = 45,
  ACTIVITY_LIST_DOWNLOAD = 48,

  ACTIVITY_PURPOSE_DELETE = 315,
  ACTIVITY_PURPOSE_BULK_UPDATE = 316,

  ACTIVITY_PURPOSE_TEMPLATE_INSERT = 340,
  ACTIVITY_PURPOSE_TEMPLATE_DELETE = 341,

  CUSTOMER_TYPE_DELETE = 353,
  CUSTOMER_TYPE_BULK_UPDATE = 351,
  CUSTOMER_TYPE_UPDATE = 352,

  ADDRESS_DELETE = 96,
  ADDRESS_GET = 93,
  ADDRESS_INSERT = 94,
  ADDRESS_LIST = 91,
  ADDRESS_SEARCH = 92,
  ADDRESS_UPDATE = 95,

  ATTACHMENT_DELETE = 116,
  ATTACHMENT_GET = 113,
  ATTACHMENT_INSERT = 114,
  ATTACHMENT_LIST = 111,
  ATTACHMENT_SEARCH = 112,
  ATTACHMENT_UPDATE = 115,

  ACCOUNT_MERGE_DELETE = 76,
  ACCOUNT_MERGE_GET = 73,
  ACCOUNT_MERGE_INSERT = 74,
  ACCOUNT_MERGE_LIST = 71,
  ACCOUNT_MERGE_SEARCH = 72,
  ACCOUNT_MERGE_UPDATE = 75,

  ACCOUNT_WARNING_SEARCH = 77,
  ACCOUNT_SEGMENT_SEARCH = 78,

  CONTACT_DELETE = 36,
  CONTACT_GET = 33,
  CONTACT_INSERT = 34,
  CONTACT_LIST = 31,
  CONTACT_SEARCH = 32,
  CONTACT_UPDATE = 35,
  CONTACT_UPDATE_STATUS = 37,
  CONTACT_LIST_DOWNLOAD = 38,
  CONTACT_SHOW_LEAD_CONTACTS = 39,
  MENU_CONTACT = 7007,

  CONTRACT_DELETE = 66,
  CONTRACT_GET = 63,
  CONTRACT_INSERT = 64,
  CONTRACT_LIST = 61,
  CONTRACT_SEARCH = 62,
  CONTRACT_UPDATE = 65,
  CONTRACT_ADMIN = 67,

  CUSTOMER_DELETE = 26,
  CUSTOMER_GET = 23,
  CUSTOMER_INSERT = 24,
  CUSTOMER_LIST = 21,
  CUSTOMER_SEARCH = 22,
  CUSTOMER_UPDATE = 25,

  DYNAMIC_FIELD_INSERT = 401,
  DYNAMIC_FIELD_SEARCH = 400,
  DYNAMIC_FIELD_UPDATE = 402,
  DYNAMIC_FIELD_DELETE = 405,

  DYNAMIC_FIELD_MANAGEMENT_ACTIVITY_PURPOSE = 7003,
  DYNAMIC_FIELD_MANAGEMENT_CUSTOMER_TYPE = 7005,

  CUSTOM_LIST_SEARCH = 475,
  CUSTOM_LIST_GET = 476,
  CUSTOM_LIST_INSERT = 477,
  CUSTOM_LIST_UPDATE = 478,
  CUSTOM_LIST_DELETE = 479,
  CUSTOM_LIST_UPDATE_ORDER = 480,
  CUSTOM_LIST_UPDATE_USERS = 481,

  CUSTOM_LIST_VALUE_SEARCH = 490,
  CUSTOM_LIST_VALUE_INSERT = 491,
  CUSTOM_LIST_VALUE_UPDATE = 492,
  CUSTOM_LIST_VALUE_DELETE = 493,

  HOME_DASHBOARD = 440,

  LEAD_DRAFT_DELETE = 86,
  LEAD_DRAFT_FILTER_OWNER = 1101,
  LEAD_DRAFT_GET = 83,
  LEAD_DRAFT_INSERT = 84,
  LEAD_DRAFT_LIST = 81,
  LEAD_DRAFT_SEARCH = 82,
  LEAD_DRAFT_UPDATE = 85,
  LEAD_DRAFT_FILTER_SALES_ORGANIZATION = 1102,
  LEAD_DRAFT_ACTION_SALES_ORGANIZATION = 1103,
  LEAD_DRAFT_LIST_DOWNLOAD = 88,

  LEAD_DRAFT_INFO_DELETE = 87,

  OFFER_DELETE = 126,
  OFFER_GET = 123,
  OFFER_INSERT = 124,
  OFFER_LIST = 121,
  OFFER_SEARCH = 122,
  OFFER_UPDATE = 125,
  OFFER_LIST_DOWNLOAD = 128,

  OPPORTUNITY_DELETE = 56,
  OPPORTUNITY_GET = 53,
  OPPORTUNITY_INSERT = 54,
  OPPORTUNITY_LIST = 51,
  OPPORTUNITY_SEARCH = 52,
  OPPORTUNITY_STATUS_SEQUENCE_ACTIVE_PASSIVE = 1209,
  OPPORTUNITY_STATUS_SEQUENCE_CANCEL_CREATED = 1210,
  OPPORTUNITY_STATUS_SEQUENCE_CONTRACT_IDENTIFICATION_ACTIVE = 1207,
  OPPORTUNITY_STATUS_SEQUENCE_CONTRACT_IDENTIFICATION_NOT_EARNED = 1208,
  OPPORTUNITY_STATUS_SEQUENCE_CREATED_CANCEL = 1202,
  OPPORTUNITY_STATUS_SEQUENCE_CREATED_OFFER_PREPARITION = 1201,
  OPPORTUNITY_STATUS_SEQUENCE_NOT_EARNED_CREATED = 1211,
  OPPORTUNITY_STATUS_SEQUENCE_OFFER_CONTRACT_IDENTIFICATION = 1205,
  OPPORTUNITY_STATUS_SEQUENCE_OFFER_NOT_EARNED = 1206,
  OPPORTUNITY_STATUS_SEQUENCE_OFFER_PREPARITION_NOT_EARNED = 1204,
  OPPORTUNITY_STATUS_SEQUENCE_OFFER_PREPARITION_OFFER = 1203,
  OPPORTUNITY_STATUS_SEQUENCE_PASSIVE_CREATED = 1212,
  OPPORTUNITY_UPDATE = 55,
  OPPORTUNITY_UPDATE_ALL = 57,
  OPPORTUNITY_OFFER_PDF = 58,
  OPPORTUNITY_COPY = 59,
  OPPORTUNITY_LIST_DOWNLOAD = 411,
  CLOSED_OPPORTUNITY_LIST_DOWNLOAD = 416,
  OPPORTUNITY_COMMERCIAL_DOCUMENT_DELETE = 412,
  OPPORTUNITY_OFFER_LIST_DOWNLOAD = 3009,
  OPPORTUNITY_CUSTOM_LIST_UPDATE = 413,
  OPPORTUNITY_COPY_SUMMARY = 414,
  OPPORTUNITY_EDIT_GENERAL_CONDITIONS = 415,
  OPPORTUNITY_APPROVAL_SEARCH = 764,
  OPPORTUNITY_APPROVAL_INSERT = 765,
  OPPORTUNITY_APPROVAL_UPDATE = 766,

  REPORT_LIST = 3000,
  REPORT_SALES_ACTIVITY = 3001,
  REPORT_OPPORTUNITY = 3002,
  REPORT_LEADS_DRAFTS = 3003,
  OFFERS_REPORT = 3004,
  SHIPMENT_PROFILE_REPORT = 3005,
  HASHTAG_REPORT = 3006,
  GAIN_REPORT = 3007,
  REPORT_OPPORTUNITY_OFFER = 3008,
  GAIN_REPORT_LIST_DOWNLOAD = 3012,
  ACCOUNT_HOME_PAGE_LISTVIEW_DOWNLOAD = 28,
  SHIPMENT_PROFILE_REPORT_LIST_DOWNLOAD = 3015,
  OFFERS_REPORT_LIST_DOWNLOAD = 3014,
  LEADS_DRAFTS_REPORT_LIST_DOWNLOAD = 3013,
  OPPORTUNITY_REPORT_LIST_DOWNLOAD = 3011,
  SALES_ACTIVITY_REPORT_LIST_DOWNLOAD = 3010,
  LISTVIEW_DOCUMENT_DOWNLOAD_SEARCH = 600,

  SHIPMENT_PROFILE_LIST_DOWNLOAD = 510,

  USER_GUIDE_LIST = 131,
  USER_GUIDE_SEARCH = 132,
  USER_GUIDE_GET = 133,
  USER_GUIDE_INSERT = 134,
  USER_GUIDE_UPDATE = 135,
  USER_GUIDE_DELETE = 136,
  USER_GUIDE_INFO_LIST = 141,
  USER_GUIDE_INFO_SEARCH = 142,
  USER_GUIDE_INFO_GET = 143,
  USER_GUIDE_INFO_INSERT = 144,
  USER_GUIDE_INFO_UPDATE = 145,
  USER_GUIDE_INFO_DELETE = 146,

  DOCUMENT_LIST = 4000,
  DOCUMENT_UPLOAD = 4001,
  DOCUMENT_DOWNLOAD = 4002,
  DOCUMENT_DELETE = 4003,
  DOCUMENT_SEARCH = 4004,
  DOCUMENT_UPDATE = 4005,
  DOCUMENT_GET = 4006,

  ASSIGNMENT_LIST = 201,
  ASSIGNMENT_SEARCH = 202,
  ASSIGNMENT_GET = 203,
  ASSIGNMENT_INSERT = 204,
  ASSIGNMENT_UPDATE = 205,
  ASSIGNMENT_DELETE = 206,
  ASSIGNMENT_LIST_DOWNLOAD = 430,
  RECURRING_TASK_INSERT = 458,
  ACTIVITY_UPDATE_ALL_USER_AUTHORİZED_ACTIVITIES = 47,
  REDIS_FLUSH_DATABASES = 5000,

  TEMP_CALENDAR = 5050,

  FINANCIAL_INQUIRY_GET = 263,
  FINANCIAL_INQUIRY_INSERT = 264,
  FINANCIAL_INQUIRY_UPDATE = 265,

  OPPORTUNITY_BUSINESS_DEVELOPMENT_UPDATE = 185,
  OPPORTUNITY_BUSINESS_DEVELOPMENT_ASSIGN_ME = 193,

  CUSTOMER_NOTE_LIST = 293,
  CUSTOMER_NOTE_SEARCH = 294,
  CUSTOMER_NOTE_GET = 295,
  CUSTOMER_NOTE_INSERT = 296,
  CUSTOMER_NOTE_UPDATE = 297,
  CUSTOMER_NOTE_DELETE = 298,

  PROFITABILITY_GET = 6002,

  CREDIT_LIMIT_UPDATE = 19,

  MANAGEMENT_PANEL = 7000,

  BOARD_ADMIN = 286,
  BOARD_LIST = 280,
  BOARD_SEARCH = 281,
  BOARD_GET = 282,
  BOARD_INSERT = 283,
  BOARD_UPDATE = 284,
  BOARD_DELETE = 285,
  BOARD_CARD_DEPENDENCY = 385,
  BOARD_LIST_DOWNLOAD = 501,
  BOARD_COPY_CARD = 500,

  SALES_CALL_MONITOR = 7001,
  CUSTOMER_NOTE_ENTRY = 7002,

  CARD_SEARCH = 288,
  CARD_GET = 289,
  CARD_INSERT = 290,
  CARD_UPDATE = 291,
  CARD_DELETE = 292,

  MEETING_TYPE_USAGE = 302,

  TRANSACTION_CURRENCY_MENU = 7004,
  TRANSACTION_CURRENCY_DEFAULT_CURRENCY_UPDATE = 321,
  TRANSACTION_CURRENCY_STATUS_UPDATE = 322,

  TRANSACTION_CURRENCY_EXCHANGE_RATE_INSERT = 331,
  TRANSACTION_CURRENCY_EXCHANGE_RATE_UPDATE = 332,
  TRANSACTION_CURRENCY_EXCHANGE_RATE_DELETE = 333,
  TRANSACTION_CURRENCY_EXCHANGE_RATE_DELETE_ALL = 334,

  ACCOUNT_REVENUE_CODE_GET = 335,

  BOARD_DYNAMIC_FIELD_INSERT = 451,
  BOARD_DYNAMIC_FIELD_UPDATE = 452,
  BOARD_DYNAMIC_FIELD_UPDATE_ORDER = 453,
  BOARD_DYNAMIC_FIELD_UPDATE_STATUS = 454,
  BOARD_DYNAMIC_FIELD_DELETE = 455,
  BOARD_DYNAMIC_FIELD_SEARCH = 456,
  BOARD_DYNAMIC_FIELD_GET = 457,

  OPPORTUNITY_STATUS_INSERT = 370,
  OPPORTUNITY_STATUS_UPDATE = 371,
  OPPORTUNITY_STATUS_DELETE = 372,

  ACCOUNT_STATUS_INSERT = 360,
  ACCOUNT_STATUS_UPDATE = 361,
  ACCOUNT_STATUS_DELETE = 362,

  SHOW_OPPORTUNITY_STATUS_PROCESS = 373,
  DEFINE_OPPORTUNITY_STATUS_PROCESS = 374,

  LANGUAGE_MANAGEMENT = 375,
  LANGUAGE_MANAGEMENT_TRANSLATION_SEARCH = 376,
  LANGUAGE_MANAGEMENT_TRANSLATION_CREATE = 377,
  LANGUAGE_MANAGEMENT_TRANSLATION_UPDATE = 378,
  LANGUAGE_MANAGEMENT_TRANSLATION_DELETE = 379,
  LANGUAGE_MANAGEMENT_LANGUAGE_UPDATE = 380,
  LANGUAGE_MANAGEMENT_LANGUAGE_INSERT = 381,
  LANGUAGE_MANAGEMENT_CUSTOM_CODE_UPDATE = 382,
  LANGUAGE_MANAGEMENT_LANGUAGE_DELETE = 383,

  BOARD_CRM_LINK = 384,

  CUSTOM_DASHBOARD_GET = 420,
  CUSTOM_DASHBOARD_INSERT = 421,
  CUSTOM_DASHBOARD_UPDATE = 422,
  CUSTOM_DASHBOARD_UPDATE_STATUS = 423,
  CUSTOM_DASHBOARD_UPDATE_USERS = 424,
  CUSTOM_DASHBOARD_SEARCH = 425,
  CUSTOM_DASHBOARD_LIST = 426,
  CUSTOM_DASHBOARD_GET_URL = 427,
  CUSTOM_DASHBOARD_DELETE = 428,
  CUSTOM_DASHBOARD_UPDATE_ORDER = 429,

  SALES_ORGANIZATION_AUTOMATIONS_SEARCH = 8000,
  SALES_ORGANIZATION_AUTOMATIONS_INSERT = 8001,
  SALES_ORGANIZATION_AUTOMATIONS_UPDATE = 8002,
  SALES_ORGANIZATION_AUTOMATIONS_UPDATE_STATUS = 8003,
  SALES_ORGANIZATION_AUTOMATIONS_DELETE = 8004,

  MAIL_MAIN_MENU = 7006,
  MAIL_LINK_INSERT = 390,
  MAIL_LINK_OBJECT_DELETE = 392,
  MAIL_LINK_LIST_SEARCH = 393,
  MAIL_LINK_DELETE = 394,
  SURVEY_SEARCH = 459,
  SURVEY_INSERT = 460,
  SURVEY_UPDATE = 461,
  SURVEY_UPDATE_STATUS = 462,
  SURVEY_DELETE = 463,

  SURVEY_MAIL_LIST_SEARCH = 464,
  SURVEY_MAIL_LIST_INSERT = 465,
  SURVEY_MAIL_LIST_UPDATE = 466,
  SURVEY_MAIL_LIST_UPDATE_STATUS = 467,
  SURVEY_MAIL_LIST_DELETE = 468,

  NPS_REPORT_LIST_DOWNLOAD = 469,
  ACTIVITY_NPS_SENDING_UPDATE = 470,

  LEAD_DRAFT_ACTIVITY_INSERT = 471,
  LEAD_DRAFT_ACTIVITY_SEARCH = 472,
  LEAD_DRAFT_ACTIVITY_UPDATE = 473,

  MENU_SURVEY_MANAGEMENT = 7008,
  MENU_NPS_REPORT = 7009,

  CUSTOMER_EXPERIENCE_MENU = 7010,

  CUSTOMER_EXPERIENCE_TICKET_SEARCH = 700,
  CUSTOMER_EXPERIENCE_TICKET_INSERT = 701,
  CUSTOMER_EXPERIENCE_TICKET_GET = 702,
  CUSTOMER_EXPERIENCE_TICKET_UPDATE = 703,
  CUSTOMER_EXPERIENCE_TICKET_LIST_DOWNLOAD = 704,

  CUSTOMER_EXPERIENCE_MESSAGE_SEARCH = 705,
  CUSTOMER_EXPERIENCE_MESSAGE_INSERT = 706,
  CUSTOMER_EXPERIENCE_MESSAGE_INSERT_ALL_USER_AUTH_CHANNEL_TYPES = 707,
  CUSTOMER_EXPERIENCE_PRIVATE_NOTE_INSERT = 708,

  CUSTOMER_EXPERIENCE_CHANNEL_SETTINGS_MENU = 709,
  CUSTOMER_EXPERIENCE_CATEGORIES_AND_STATUSES_MENU = 710,
  CUSTOMER_EXPERIENCE_AUTOMATIONS_MENU = 711,
  CUSTOMER_EXPERIENCE_SLA_MENU = 712,
  CUSTOMER_EXPERIENCE_QUICK_REPLIES_MENU = 713,
  CUSTOMER_EXPERIENCE_WORKING_HOURS_MENU = 714,
  CUSTOMER_EXPERIENCE_CHARTS_MENU = 715,

  CUSTOMER_EXPERIENCE_CHANNEL_SETTINGS_SEARCH = 716,
  CUSTOMER_EXPERIENCE_CHANNEL_SETTINGS_INSERT = 717,
  CUSTOMER_EXPERIENCE_CHANNEL_SETTINGS_GET = 718,
  CUSTOMER_EXPERIENCE_CHANNEL_SETTINGS_UPDATE = 719,
  CUSTOMER_EXPERIENCE_CHANNEL_SETTINGS_DELETE = 720,
  CUSTOMER_EXPERIENCE_CHANNEL_SETTINGS_UPDATE_STATUS = 721,

  CUSTOMER_EXPERIENCE_CATEGORIES_AND_STATUSES_SEARCH = 722,
  CUSTOMER_EXPERIENCE_CATEGORIES_AND_STATUSES_INSERT = 723,
  CUSTOMER_EXPERIENCE_CATEGORIES_AND_STATUSES_GET = 724,
  CUSTOMER_EXPERIENCE_CATEGORIES_AND_STATUSES_UPDATE = 725,
  CUSTOMER_EXPERIENCE_CATEGORIES_AND_STATUSES_DELETE = 726,
  CUSTOMER_EXPERIENCE_CATEGORIES_AND_STATUSES_UPDATE_ORDER = 727,
  CUSTOMER_EXPERIENCE_CATEGORIES_AND_STATUSES_UPDATE_STATUS = 728,

  CUSTOMER_EXPERIENCE_AUTOMATION_SEARCH = 729,
  CUSTOMER_EXPERIENCE_AUTOMATION_INSERT = 730,
  CUSTOMER_EXPERIENCE_AUTOMATION_GET = 731,
  CUSTOMER_EXPERIENCE_AUTOMATION_UPDATE = 732,
  CUSTOMER_EXPERIENCE_AUTOMATION_DELETE = 733,
  CUSTOMER_EXPERIENCE_AUTOMATION_UPDATE_ORDER = 734,
  CUSTOMER_EXPERIENCE_AUTOMATION_UPDATE_STATUS = 735,

  CUSTOMER_EXPERIENCE_SLA_SEARCH = 736,
  CUSTOMER_EXPERIENCE_SLA_INSERT = 737,
  CUSTOMER_EXPERIENCE_SLA_GET = 738,
  CUSTOMER_EXPERIENCE_SLA_UPDATE = 739,
  CUSTOMER_EXPERIENCE_SLA_DELETE = 740,
  CUSTOMER_EXPERIENCE_SLA_UPDATE_ORDER = 741,
  CUSTOMER_EXPERIENCE_SLA_UPDATE_STATUS = 742,

  CUSTOMER_EXPERIENCE_QUICK_REPLIES_SEARCH = 743,
  CUSTOMER_EXPERIENCE_QUICK_REPLIES_INSERT = 744,
  CUSTOMER_EXPERIENCE_QUICK_REPLIES_GET = 745,
  CUSTOMER_EXPERIENCE_QUICK_REPLIES_UPDATE = 746,
  CUSTOMER_EXPERIENCE_QUICK_REPLIES_DELETE = 747,
  CUSTOMER_EXPERIENCE_QUICK_REPLIES_UPDATE_ORDER = 748,
  CUSTOMER_EXPERIENCE_QUICK_REPLIES_UPDATE_STATUS = 749,

  CUSTOMER_EXPERIENCE_WORKING_HOURS_SEARCH = 750,
  CUSTOMER_EXPERIENCE_WORKING_HOURS_GET = 751,
  CUSTOMER_EXPERIENCE_WORKING_HOURS_UPDATE = 752,
  CUSTOMER_EXPERIENCE_WORKING_HOURS_UPDATE_STATUS = 753,

  CUSTOMER_EXPERIENCE_CHART_SEARCH = 754,
  CUSTOMER_EXPERIENCE_CHART_GET = 755,
  CUSTOMER_EXPERIENCE_CHART_UPDATE = 756,
  CUSTOMER_EXPERIENCE_CHART_UPDATE_ORDER = 757,
  CUSTOMER_EXPERIENCE_CHART_UPDATE_STATUS = 758,

  CUSTOMER_EXPERIENCE_LINK_LIST_SEARCH = 759,
  CUSTOMER_EXPERIENCE_LINK_LIST_DELETE = 760,
  CUSTOMER_EXPERIENCE_LINK_LIST_INSERT = 761,

  CUSTOMER_EXPERIENCE_TICKET_MERGE = 762,
  CUSTOMER_EXPERIENCE_TICKET_SPLIT = 763

}
