<div class="card flex-auto border" @fadeInUp>
  <div class="card-title flex border-b items-center" (mousedown)="onMouseDown($event)">
    <p class="text-lg pl-gutter w-full preview-drag-handle">
      <span matTooltipShowDelay="800" [matTooltip]="preview.subject" [matTooltipDisabled]="preview.subject.length < 30">
        {{ preview.subject || ('MAIL.ENTRY_NO_SUBJECT' | translate) }}
      </span>
    </p>
    <button type="button" mat-icon-button (click)="toggleExpand()">
      <mat-icon [icIcon]="expand ? icFullscreenExit : icFullscreen" size="18px"></mat-icon>
    </button>
    <button type="button" mat-icon-button (click)="close()">
      <mat-icon [icIcon]="icClose" size="18px"></mat-icon>
    </button>
  </div>

  <div class="flex items-center pl-gutter py-2 border-b">
    <div class="flex-1 flex truncate">
      <div class="flex-none flex flex-col ltr:pr-4 rtl:pl-4">
        <net-mail-circle-avatar [key]="preview.fromMailAdress" [identifier]="preview.from"></net-mail-circle-avatar>
      </div>

      <div class="flex-1 truncate">
        <div class="flex truncate">
          <p class="text-base flex-1 truncate font-medium">{{ preview.from }}</p>
        </div>
        <div class="flex items-center">
          <p class="text-xs text-secondary">{{ 'MAIL.TO' | translate | lowercase }} {{ preview.to }}</p>
        </div>
      </div>
    </div>

    <button type="button" class="mr-2 px-2" mat-button (click)="open()" *ngIf="hasMailMenu && preview.mailId">
      <mat-icon [icIcon]="icOpenInNew" size="18px"></mat-icon>
      {{ 'GENERAL.OPEN_IN_MAILBOX' | translate }}
    </button>
  </div>

  <div class="html-view-container" netScrollbar>
    <net-html-viewer [html]="preview.body"
      defaultStyle="body { margin: 0; padding: 0; } [data-content-wrapper] { padding: 1.5rem; }"></net-html-viewer>

    <div class="p-gutter pt-0 grid gap-2 grid-cols-2" [class.grid-cols-6]="expand" *ngIf="preview?.files?.length > 0">
      <net-mail-attachment [file]="file" [mailId]="preview.mailId" [directDownload]="true"
        *ngFor="let file of preview.files"></net-mail-attachment>
    </div>
  </div>
</div>