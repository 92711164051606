import { Response } from '@core/api';
import { StaticField, StaticFieldFilterRequest } from '@core/api/static-field/static-field.model';
import { Action } from '@ngrx/store';

export enum StaticFieldActionTypes {
    GET_STATIC_FIELD = '[Static Field] GET_STATIC_FIELD',
    GET_STATIC_FIELD_SUCCESS = '[Static Field] GET_STATIC_FIELD_SUCCESS',
    GET_STATIC_FIELD_ERROR = '[Static Field] GET_STATIC_FIELD_ERROR',
    GET_STATIC_FIELD_CLEAR = '[Static Field] GET_STATIC_FIELD_CLEAR'
}

export class GetStaticField implements Action {
    readonly type = StaticFieldActionTypes.GET_STATIC_FIELD;
    constructor(public payload: StaticFieldFilterRequest) { }
}

export class GetStaticFieldSuccess implements Action {
    readonly type = StaticFieldActionTypes.GET_STATIC_FIELD_SUCCESS;
    constructor(public payload: StaticField[]) { }
}

export class GetStaticFieldError implements Action {
    readonly type = StaticFieldActionTypes.GET_STATIC_FIELD_ERROR;
    constructor(public payload: Response<null>) { }
}

export class GetStaticFieldClear implements Action {
    readonly type = StaticFieldActionTypes.GET_STATIC_FIELD_CLEAR;
    constructor() { }
}

export type StaticFieldActions = GetStaticField | GetStaticFieldSuccess | GetStaticFieldError | GetStaticFieldClear;
