import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import * as DOMPurify from 'dompurify';
import { IFrameComponent, iframeResizer } from 'iframe-resizer';
import { Style, StyleService } from 'src/app/layout/services/style.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'net-html-viewer[html]',
  template: '<iframe #iframe [src]="url" class="iframe" style="width: 1px; min-width: 100%;"></iframe>',
  styleUrls: ['./html-viewer.component.scss'],
})
export class HtmlViewerComponent implements AfterViewInit, OnInit, OnDestroy {
  url: SafeResourceUrl;
  component: IFrameComponent;
  private _html = null;

  @Input() defaultStyle = `body { margin: 0; padding: 0 }`;

  @ViewChild('iframe') iframe: ElementRef<HTMLIFrameElement>;

  @Input() set html(html: string) {
    this._html = html;
    const page = DOMPurify.sanitize(html, {
      ADD_TAGS: ['head', 'meta'],
      ADD_ATTR: ['content', 'http-equiv'],
      FORBID_TAGS: ['script'],
      RETURN_DOM: true,
      WHOLE_DOCUMENT: true,
    });

    // Create wrapper element for page content
    const wrapper = document.createElement('div');
    wrapper.setAttribute('data-iframe-width', '');
    wrapper.setAttribute('data-iframe-height', '');
    wrapper.setAttribute('data-content-wrapper', '');

    // Update wrapper content with page content
    wrapper.innerHTML = page.querySelector('body').innerHTML;

    // Replace body content with wrapper
    page.querySelector('body').innerHTML = wrapper.outerHTML;

    // Append default style
    if (this.defaultStyle) {
      const style = document.createElement('style');
      style.innerHTML = this.defaultStyle;
      page.querySelector('head').appendChild(style);
    }

    // Append meta tag
    const hasMetaTag = page.querySelector('meta');
    if (!hasMetaTag) {
      const meta = document.createElement('meta');
      meta.setAttribute('content', 'text/html; charset=utf-8');
      meta.setAttribute('http-equiv', 'Content-Type');
      page.querySelector('head').appendChild(meta);
    } else {
      hasMetaTag.setAttribute('content', 'text/html; charset=utf-8');
      hasMetaTag.setAttribute('http-equiv', 'Content-Type');
    }

    // Append iframe resizer
    const script = document.createElement('script');
    script.src = 'https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.3.3/iframeResizer.contentWindow.min.js';
    page.querySelector('body').appendChild(script);

    // Update frame url
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(`data:text/html,${encodeURIComponent(page.outerHTML)}`);
  }

  constructor(private sanitizer: DomSanitizer, private styleService: StyleService) {
    // Replace target with blank by default
    DOMPurify.addHook('afterSanitizeAttributes', (node) => {
      if ('target' in node) {
        node.setAttribute('rel', 'noopener');
        node.setAttribute('target', '_blank');
      }
    });
  }

  ngOnInit() {
    this.styleService.style$.pipe(untilDestroyed(this)).subscribe((style) => {
      this.html = this._html;
    });
  }

  ngAfterViewInit() {
    // Create iframe resizer components
    const components = iframeResizer({
      checkOrigin: false,
      widthCalculationMethod: 'taggedElement',
      heightCalculationMethod: 'taggedElement',
    }, this.iframe.nativeElement);

    // Update component reference with first frame
    this.component = components && components.length > 0 ? components[0] : null;
  }

  ngOnDestroy(): void {
    // Close iframe resizer when exists
    if (this.component && this.component.iFrameResizer) {
      this.component.iFrameResizer.close();
    }
  }
}
