import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { FilterRequest, Response, AssignmentStatus } from '@core/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AssignmentStatusService {
  constructor(private http: HttpClient) { }

  list(request: FilterRequest): Observable<Response<AssignmentStatus[]>> {
    return this.http.post<Response<AssignmentStatus[]>>(environment.apiUrl + '/api/AssignmentStatus/List', request);
  }
}
