import { NgxUiLoaderConfig, NgxUiLoaderHttpModule, NgxUiLoaderModule } from 'ngx-ui-loader';
import { GoogleMapsModule } from '@angular/google-maps';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import localeTr from '@angular/common/locales/tr';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeDe from '@angular/common/locales/de';
import localeNl from '@angular/common/locales/nl';

import { APP_INITIALIZER, ErrorHandler, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LanguageDataPassService } from '@core/api';
import { ApiInterceptor } from '@core/api/api.interceptor';
import { NotePassService } from '@core/api/note/note-pass.service';
import { AuthInterceptor } from '@core/auth/auth.interceptor';
import { appEffects, appReducer } from '@core/store';
import { environment } from '@env/environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { LocaleProvider } from './locale.provider';
import { CustomErrorHandlerService } from './shared/services/custom-error-handler.service';
import { GeocodeService } from './shared/services/geocode.service';
import { AppMissingTranslationHandler } from './shared/services/translate/app-missing-translate-handler.service';
import { NotTranslatedService } from './shared/services/translate/not-translated.service';
import { TranslationService } from './shared/services/translate/translation.service';
import { ApplicationInitializerFactory } from './translation.config';
import { GoogleTranslateService } from './shared/services/google-translate.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsColor: '#2fbfcb',
  fgsPosition: 'center-center',
  fgsType: 'pulse',
  hasProgressBar: false
};

let googleTagManager = [
  GoogleTagManagerModule.forRoot({
    id: localStorage.getItem('googleTagManagerId')
  })
];

if (localStorage.getItem('googleTagManagerId') === '@TODO') {
  googleTagManager = [];
}

registerLocaleData(localeTr);
registerLocaleData(localeDe);
registerLocaleData(localeFr);
registerLocaleData(localeEs);
registerLocaleData(localeNl);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationService,
        deps: [HttpClient]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: AppMissingTranslationHandler,
        deps: [NotTranslatedService]
      },
      isolate: false,
      extend: true
    }),
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    LayoutModule,
    ...googleTagManager,
    GoogleMapsModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule.forRoot({
      showForeground: true,
      delay: 2000,
      maxTime: 20000,
      excludeRegexp: [
        '/api/Notification/GetActionNeededCounts',
        '/api/Board/SocketSearch',
        '/api/Report/ActivityReportTableData',
        '/api/Report/ActivityReportPurposeSummaryTableData',
        '/api/Report/ActivityReportSummaryTableData',
        '/api/Report/LeadsDraftsReportSummaryTableData',
        '/api/Report/LeadsDraftsReportTableData',
        '/api/Report/OffersReportSummaryTableData',
        '/api/Report/OffersReportTableData',
        '/api/Report/ActivityTrendDoughnutChartData',
        '/api/Report/OpportunityReportGroupedByStatusTrendChart',
        '/api/Report/OpportunityReportExpectedGiroChart',
        '/api/Report/OpportunityReportExpectedGiroByStatusChart',
        '/api/Report/OpportunityReportStatusChangeChart',
        '/api/Report/OpportunityReportTableData',
        '/api/Report/GainReportTrendChartData',
        '/api/Report/GainReportTableData',
        '/api/Report/OffersDoughnutChartData',
        '/api/AccountRevenue/GetWithProductionAccountCode',
        '/api/SystemUserPicture/GetPicture'
      ]
    }),
    StoreModule.forRoot(appReducer, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false
      },
    }),
    EffectsModule.forRoot(appEffects),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    MatTooltipModule,
  ],
  providers: [
    LocaleProvider,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: ApplicationInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandlerService
    },
    LanguageDataPassService,
    GeocodeService,
    GoogleTranslateService,
    NotePassService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
