import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {
  CXChannelType, CustomerExperienceCategoriesService, CustomerExperienceChannelSettingsService, CustomerExperienceLink,
  CustomerExperienceLinkType, CustomerExperienceMainCategoriesService, CustomerExperienceTicket, CustomerExperienceTicketLinksService,
  CustomerExperienceTicketService, SystemSettingType, SystemUserService, TicketLinkSearchRequest, User
} from '@core/api';
import hasClassParents from '../../utils/has-class-parents';
import icSearch from '@iconify/icons-ic/twotone-search';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icMoreHoriz from '@iconify/icons-ic/twotone-more-horiz';
import icFilterList from '@iconify/icons-ic/twotone-filter-list';
import icLayersClear from '@iconify/icons-ic/twotone-layers-clear';
import icKeyboardArrowUp from '@iconify/icons-ic/keyboard-arrow-up';
import icKeyboardArrowDown from '@iconify/icons-ic/keyboard-arrow-down';
import icOpenLink from '@iconify/icons-ic/open-in-new';
import icRemoveLink from '@iconify/icons-ic/outline-link-off';
import pcTicket from '@iconify/icons-pepicons-pencil/ticket-circle-filled';
import icWhatsapp from '@iconify/icons-logos/whatsapp-icon';
import tablerMail from '@iconify-icons/tabler/mail-filled';
import mdiTicket from '@iconify/icons-mdi/ticket-confirmation-outline';
import { FormControl } from '@angular/forms';
import { MatHeaderFilterType } from '../mat-header-filter/mat-header-filter.component.enum';
import { TableColumnFilterConfig } from '../mat-table-column-filter/mat-table-column-filter.component';
import { TableColumn } from '../../interfaces/table-column.interface';
import { CustomerExperienceTicketPreviewService } from 'src/app/layout/customer-experience-ticket-preview/customer-experience-ticket-preview.service';
import { getSystemSettingValue, getUser, hasUserPermission } from '@core/store';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Permission } from '@core/auth/auth.enum';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { CustomerExperienceTicketLinkDataSource } from '@core/api/customer-experience-ticket-link/customer-experience-ticket-link.data-source';
import { filter, take } from 'rxjs/operators';
import { stagger40ms } from '../../animations/stagger.animation';
import { fadeInUp400ms } from '../../animations/fade-in-up.animation';
import { fadeInRight400ms } from '../../animations/fade-in-right.animation';
import { UserConfigService } from '@core/user-config/user-config.service';
import { updateColumnOrder, updateUserColumnVisibility, watchUserTableSort } from '../../utils/mat-table-user-config';

@UntilDestroy()
@Component({
  selector: 'net-ticket-link-list[type][objectId][showIcon]',
  templateUrl: './ticket-link-list.component.html',
  styleUrls: ['./ticket-link-list.component.scss'],
  animations: [
    stagger40ms,
    fadeInUp400ms,
    fadeInRight400ms,
  ]
})
export class TicketLinkListComponent implements OnInit, AfterViewInit {
  initiated = false;

  searchCtrl = new FormControl();
  dataSource: CustomerExperienceTicketLinkDataSource;

  pageSize = 10;
  pageSizeOptions = [5, 10, 20, 50];

  hasPreviews = false;
  matHeaderFilterType = MatHeaderFilterType;
  cxChannelType = CXChannelType;

  hasDeleteCXLinkPermitted = false;
  hasMenuCXPermitted = false;

  user: User;
  columns: TableColumn<CustomerExperienceLink>[] = [];
  defaultColumns: TableColumn<CustomerExperienceLink>[] = [];
  tableColumnFilterConfig: TableColumnFilterConfig;
  translations: any;

  icSearch = icSearch;
  icMoreHoriz = icMoreHoriz;
  icFilterList = icFilterList;
  icLayersClear = icLayersClear;
  icKeyboardArrowUp = icKeyboardArrowUp;
  icKeyboardArrowDown = icKeyboardArrowDown;
  icOpenLink = icOpenLink;
  icRemoveLink = icRemoveLink;
  pcTicket = pcTicket;
  icWhatsapp = icWhatsapp;
  tablerMail = tablerMail;
  mdiTicket = mdiTicket;

  @Input() type: CustomerExperienceLinkType;
  @Input() objectId: string;
  @Input() accountId: string;
  @Input() showIcon: boolean;
  @Input() salesOrganizationIds: string[];

  @Input() collapse = true;
  @Input() collapsible = false;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private router: Router,
    private store: Store,
    private translate: TranslateService,
    private customerExperienceLinksService: CustomerExperienceTicketLinksService,
    private channelService: CustomerExperienceChannelSettingsService,
    private mainCategoryService: CustomerExperienceMainCategoriesService,
    private categoryService: CustomerExperienceCategoriesService,
    private ticketService: CustomerExperienceTicketService,
    private customerExperienceTicketPreviewService: CustomerExperienceTicketPreviewService,
    private userConfig: UserConfigService,
    private cdr: ChangeDetectorRef,
    private systemUserService: SystemUserService
  ) {

    this.translate.get(['CX', 'GENERAL']).subscribe(translations => {
      this.translations = translations;
    });

    this.store.select(hasUserPermission(Permission.CUSTOMER_EXPERIENCE_LINK_LIST_DELETE))
      .pipe(untilDestroyed(this)).subscribe(hasDeleteCXLinkPermitted => this.hasDeleteCXLinkPermitted = hasDeleteCXLinkPermitted);

    this.store.select(hasUserPermission(Permission.CUSTOMER_EXPERIENCE_MENU))
      .pipe(untilDestroyed(this)).subscribe(hasMenuCXPermitted => this.hasMenuCXPermitted = hasMenuCXPermitted);

    this.store.select(getUser).pipe(untilDestroyed(this)).subscribe(user => {
      this.user = user;
    });


    this.store
      .select(getSystemSettingValue(SystemSettingType.CX_MAIN_CATEGORY_USAGE))
      .pipe(untilDestroyed(this))
      .subscribe((result: boolean) => {
        this.initColumns(result);
      });

    this.customerExperienceTicketPreviewService.hasPreview$.pipe(untilDestroyed(this))
      .subscribe(previewLength => this.hasPreviews = previewLength);
  }

  get visibleColumns() {
    return this.columns.filter(column => column.visible).map(column => column.property);
  }

  ngOnInit(): void {
    const initialFilter: TicketLinkSearchRequest['filter'] = {
      salesOrganizationIds: this.salesOrganizationIds,
      accountId: this.accountId,
      ticketSearch: true
    };

    // Apply customer type account filter when exists
    // Otherwise apply ticket link type filter directly
    if (CustomerExperienceLinkType.CUSTOMER === this.type) {
      initialFilter.accountId = this.objectId;
    }

    // Apply object id filter by type
    switch (this.type) {
      case CustomerExperienceLinkType.CARD: initialFilter.cardId = this.objectId; break;
      case CustomerExperienceLinkType.CONTACT: initialFilter.contactId = this.objectId; break;
      case CustomerExperienceLinkType.ACTIVITY: initialFilter.activityId = this.objectId; break;
      case CustomerExperienceLinkType.CUSTOMER: initialFilter.accountId = this.objectId; break;
      case CustomerExperienceLinkType.ASSIGNMENT: initialFilter.assignmentId = this.objectId; break;
      case CustomerExperienceLinkType.LEAD_DRAFT: initialFilter.leadDraftId = this.objectId; break;
      case CustomerExperienceLinkType.OPPORTUNITY: initialFilter.opportunityId = this.objectId; break;
    }

    this.dataSource = new CustomerExperienceTicketLinkDataSource(
      this.ticketService,
      this.channelService,
      this.mainCategoryService,
      this.categoryService,
      this.customerExperienceLinksService,
      this.systemUserService,
      this.user,
      this.translate,
      initialFilter);

    // Create table column filter config
    this.tableColumnFilterConfig = {
      sortId: `ticket-link-list-${this.type}-${this.objectId}`,
      orderId: `ticketLinkList${this.type}-${this.objectId}`,
      visibilityId: `ticket-link-list-${this.type}-${this.objectId}`
    };

    // Listen loading event for determine data source is initiated
    this.dataSource.loading$.pipe(filter(value => value === false), take(1)).subscribe(() => this.initiated = true);

    // Listen for search
    this.searchCtrl.valueChanges
      .pipe(
        untilDestroyed(this),
        filter(value => value?.length > 2 || value?.length === 0)
      ).subscribe(value => this.dataSource.keyword$.next(value));
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    if (this.type !== CustomerExperienceLinkType.CONTACT) {
      this.columns.splice(1, 0, {
        label: this.translations?.GENERAL.CONTACT,
        property: 'contact',
        type: 'contactName',
        visible: true,
        filterable: true,
        key: 'contact'
      });
    }

    if (this.type === CustomerExperienceLinkType.CUSTOMER) {
      this.columns.splice(13, 0, {
        label: this.translations?.CX.LINK_TYPE,
        property: 'ticketLinkTypeId',
        type: 'uuid',
        visible: true,
        filterable: true,
        key: 'ticketLinkTypeIds'
      });
    }

    this.store.select(getUser).pipe(untilDestroyed(this))
      .subscribe(() => {

        // Update column visibility by user config
        updateUserColumnVisibility(this.tableColumnFilterConfig.visibilityId, this.columns, this.userConfig);
        updateColumnOrder(this.tableColumnFilterConfig.orderId, this.columns, this.userConfig);

        // Watch table sort changes for user config
        watchUserTableSort(this.tableColumnFilterConfig.sortId, this.sort, this.userConfig, this.columns);

        this.cdr.detectChanges();
      });
  }

  toggleCollapse(event) {
    if (!this.collapsible) {
      return;
    }

    if (hasClassParents(event.target, 'ignore-toggle-click')) {
      return;
    }

    this.collapse = !this.collapse;
  }

  private initColumns(cxMainCategoryUsage: boolean) {
    this.columns = [
      {
        label: this.translations?.GENERAL.ID,
        property: 'ticketCode',
        type: 'code',
        visible: true,
        filterable: true
      },
      {
        label: this.translations?.CX.CHANNEL_TYPE,
        property: 'cxChannelType',
        type: 'cxChannelType',
        visible: true,
        filterable: true,
        key: 'cxChannelTypeIds'
      },
      {
        label: this.translations?.CX.CHANNEL_NAME,
        property: 'cxChannelName',
        type: 'cxChannelName',
        visible: false,
        filterable: true,
        key: 'cxChannelIds'
      },
      {
        label: this.translations?.GENERAL.SUBJECT,
        property: 'subject',
        type: 'name',
        visible: true,
        filterable: true,
        key: 'subject'
      },
      ...(cxMainCategoryUsage ? [{
        label: this.translations?.CX.MAIN_CATEGORY,
        property: 'mainCategory',
        type: 'cxMainCategory',
        visible: true,
        filterable: true,
        key: 'cxMainCategoryIds',
      } as TableColumn<any>] : []),
      {
        label: this.translations?.CX.CATEGORY,
        property: 'category',
        type: 'cxMainCategory',
        visible: true,
        filterable: true,
        key: 'cxCategoryIds',
      },
      {
        label: this.translations?.CX.PRIORITY,
        property: 'priority',
        type: 'number',
        visible: false,
        filterable: true,
        key: 'ticketPriorityIds',
      },
      {
        label: this.translations?.CX.TICKET_DATE,
        property: 'ticketDate',
        type: 'date',
        visible: true,
        filterable: true,
        key: 'ticketDate',
      },
      {
        label: this.translations?.CX.COMPLETION_DATE,
        property: 'completionDate',
        type: 'date',
        visible: false,
        filterable: true,
        key: 'completionDate',
      },
      {
        label: this.translations?.CX.STATUS,
        property: 'status',
        type: 'status',
        visible: true,
        filterable: true,
        key: 'cxStatusIds'
      },
      {
        label: this.translations?.CX.SUB_STATUS,
        property: 'subStatus',
        type: 'status',
        visible: true,
        filterable: true,
        key: 'cxSubStatusIds'
      },
      {
        label: this.translations?.GENERAL.ASSIGNEE,
        property: 'assignee',
        type: 'cardUsers',
        visible: true,
        filterable: true,
        key: 'assigneeIds'
      },
      {
        label: this.translations?.CX.LINKED_BY,
        property: 'linkedBy',
        type: 'linkedBy',
        visible: true,
        filterable: true,
        key: 'createdBys'
      },
      {
        label: this.translations?.GENERAL.ACTIONS,
        property: 'actions',
        type: 'button',
        visible: true,
        filterable: false
      }
    ];
  }

  clearPreviews() {
    this.customerExperienceTicketPreviewService.clear();
  }

  openCxModule(link: CustomerExperienceLink & { ticket: Partial<CustomerExperienceTicket> }) {
    window.open(
      this.router.serializeUrl(this.router.createUrlTree(['/customer-experience', 'ticket', link.ticket?.mergedTicketId ?? link.ticketId])),
      '_blank'
    );
  }

  deleteLink(row: CustomerExperienceLink) {
    Swal.fire({
      html: this.translate.instant('CX.YOU_ARE_ABOUT_DELETE_TICKET_LINK'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#4caf50',
      confirmButtonText: this.translate.instant('GENERAL.CONFIRM'),
      cancelButtonText: this.translate.instant('GENERAL.CANCEL')
    }).then((result) => {
      if (!result.value) {
        return;
      }

      this.customerExperienceLinksService.link
        .delete({ ticketLinkId: row.ticketLinkId }, false)
        .subscribe(() => this.dataSource.refresh());
    });
  }

  openPreview(ticket: CustomerExperienceTicket) {
    this.customerExperienceTicketPreviewService.addLink(ticket);
  }
  ticketLinkTypeName(typeId: string) {
    return getTicketLinkTypeName(typeId, this.translate);
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

}

export function getTicketLinkTypeName(mailLinkTypeId: string, translate: TranslateService): string {
  switch (mailLinkTypeId) {
    case CustomerExperienceLinkType.CARD: return translate.instant('GENERAL.MAIL_LINK_TYPE_CARD');
    case CustomerExperienceLinkType.CONTACT: return translate.instant('GENERAL.MAIL_LINK_TYPE_CONTACT');
    case CustomerExperienceLinkType.CUSTOMER: return translate.instant('GENERAL.MAIL_LINK_TYPE_CUSTOMER');
    case CustomerExperienceLinkType.ACTIVITY: return translate.instant('GENERAL.MAIL_LINK_TYPE_ACTIVITY');
    case CustomerExperienceLinkType.LEAD_DRAFT: return translate.instant('GENERAL.MAIL_LINK_TYPE_LEAD_DRAFT');
    case CustomerExperienceLinkType.ASSIGNMENT: return translate.instant('GENERAL.MAIL_LINK_TYPE_ASSIGNMENT');
    case CustomerExperienceLinkType.OPPORTUNITY: return translate.instant('GENERAL.MAIL_LINK_TYPE_OPPORTUNITY');
  }

  return 'N/A';
}
