export enum SystemSettingTypeAsString {
  CUSTOMER_CODE = '99a0c9ce-2a83-4ce0-b5dd-9b1be0671c06',
  LOGIN_VIDEO = '64c80dd5-4d43-41a7-94f1-a237cc3f7d84',
  LOGIN_IMAGE = '2fe23716-a6f6-4033-b41a-292e2762e5f3',
  GOOGLE_ANALYTICS = '6437f4e3-5401-403b-b064-552d2a731c57',
  TITLE = '7299c3f7-edc1-4f33-b392-05a0e0deda5e',
  LOGO_DARK = 'd76b5599-3dfc-423c-b20a-33b2dbfec22b',
  LOGO_LIGHT = '3b73600e-5094-435e-960b-4c05d172515a',
  FAVICON = 'a0de85e8-e477-4f1a-9685-dc867bd28b6c',
  PROFILE_BANNER = '9661f924-87cc-4e0b-a92e-069b05ad37fb',
  DECIMAL_USAGE = '3d2cb349-f916-4222-96d4-6ca5101e40e0',
  ORGANIZATION_CODE_USAGE = '3427336d-2ba3-4797-a8aa-79c6350868a9',
  USER_GUIDE = '00d3d528-3b26-454f-80e5-fbb9361e8a02',
  MAIL_PROVIDERS = '7573656c-e403-4e7f-bdc7-e8678d93d4f4',
  MAIL_SCAN = '84a9c9ac-c47e-4cac-bb6d-3446da3d3003',
  ASSIGNMENT_TIME = 'c9a34077-3308-4e53-a45e-a32b0d8c3420',
  NPS_MAX_UPDATE_LIMIT = '9d6ff8a2-93c0-42b1-8562-887fb5b9e5c1',
  NPS_SURVEY_EXPIRATION_DAYS = '76592219-b448-485e-b7ae-f572e2f652a5',
  NPS_MAX_NUMBER_OF_CONTACT = '4ee90186-10e7-46dc-aaac-e8cc80f99b77',
  CX_MAIN_CATEGORY_USAGE = '021f32db-4e90-4325-85cc-2b9871c80b38',
  ACCOUNT_USAGE_WITHOUT_LEAD_DRAFT = '388fbb4f-f410-4189-ac10-7d1916289b3e',
  CX_CHANNELS = '0fb69e61-13fb-4b6c-ba69-0dc66d4e8f88'
}

export enum SystemSettingType {
  CUSTOMER_CODE = 'externalNaming',
  LOGIN_VIDEO = 'loginVideo',
  LOGIN_IMAGE = 'loginImage',
  GOOGLE_ANALYTICS = 'googleAnalytics',
  TITLE = 'title',
  LOGO_DARK = 'logoDarkTheme',
  LOGO_LIGHT = 'logolightTheme',
  FAVICON = 'favicon',
  PROFILE_BANNER = 'profileCover',
  DECIMAL_USAGE = 'decimalPoint',
  ORGANIZATION_CODE_USAGE = 'organizationCode',
  USER_GUIDE = 'userGuide',
  MAIL_PROVIDERS = 'mailProviders',
  MAIL_SCAN = 'mailScan',
  ASSIGNMENT_TIME = 'taskCron',
  NPS_MAX_UPDATE_LIMIT = 'npsUpdateLimit',
  NPS_SURVEY_EXPIRATION_DAYS = 'npsSurveyExpiration',
  NPS_MAX_NUMBER_OF_CONTACT = 'npsEmailLimit',
  CX_MAIN_CATEGORY_USAGE = 'cxCategory',
  ACCOUNT_USAGE_WITHOUT_LEAD_DRAFT = 'leadless',
  CX_CHANNELS = 'cxChannels',
  SALES_ORGANIZATION_ID = 'salesOrganizationId',
  TRANSACTION_CURRENCY = 'transactionCurrency',
  MAIL_LOGO = 'mailLogo',
  CULTURE = 'culture',
  CUSTOM_CULTURE = 'customCulture'
}

export enum SystemSettingMailProviderType {
  GMAIL = 'e8af6af3-60b3-4e27-9f74-04f868fc12f4',
  OUTLOOK = '623ce296-725f-4c05-84fa-35645af524ff',
  NO_PROVIDER = 'f85d3ec5-7757-46f5-91cc-1fa4d53da943',
}

export enum SystemSettingControlTypes {
  BOOLEAN = 'boolean',
  INTEGER = 'integer',
  TEXT = 'text',
  SINGLE = 'single',
  MULTIPLE = 'multiple',
}

export enum SystemSettingDecimalUsageTypes {
  COMMA = 'a118a704-67d0-44c2-a4e7-410110b62811',
  POINT = 'fafb2018-e3f7-470d-83be-d52648152b3f'
}
