<mat-select #contactFilterSetSelect [placeholder]="placeholder" [formControl]="selectCtrl"
    (openedChange)="openedChange($event)" (closed)="search.value = ''; filteredContactList = contactList"
    (selectionChange)="onSelectionChange()" [compareWith]="compareWith">

    <input #search (keydown)="$event.stopPropagation()" (keyup)="onKey($event)" matInput class="pt-4 pb-2 pl-4 w-full"
        maxlength="10" placeholder="{{'GENERAL.SEARCH_ELLIPSIS' | translate}}" type="text">

    <mat-option *ngFor="let item of filteredContactList" [value]="item.id"
        (click)="selectedFilterSet.emit(item)">{{item.name}}</mat-option>

</mat-select>