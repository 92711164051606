<div class="card flex-auto border" @fadeInUp>

    <div *ngIf="isLoading" class="card-title flex border-b items-center" style="height: 50px;" fxLayout="column"
        fxLayout.gt-xs="row" fxLayoutGap.gt-xs="8px">
        <ngx-skeleton-loader fxFlex appearance="line"></ngx-skeleton-loader>
        <ngx-skeleton-loader fxFlex="5" appearance="line"></ngx-skeleton-loader>
        <ngx-skeleton-loader fxFlex="5" appearance="line"></ngx-skeleton-loader>
        <ngx-skeleton-loader fxFlex="5" appearance="line"></ngx-skeleton-loader>

    </div>

    <div *ngIf="!isLoading" class="card-title flex border-b items-center" style="height: 50px;"
        (mousedown)="onMouseDown($event)">
        <p class="text-lg pl-gutter w-full preview-drag-handle">
            <span style="color: #7a6ed4;">
                {{preview.code}}
            </span>
            {{ contactName ?? preview.contactInfo }}
        </p>

        <button type="button" class="mr-2 px-2" mat-button (click)="open()"
            [matTooltip]="'CX.OPEN_IN_CX_MODULE' |translate">
            <mat-icon [icIcon]="icOpenInNew" size="18px"></mat-icon>
        </button>
        <button type="button" class="mr-2 px-2" mat-button (click)="toggleExpand()"
            [matTooltip]="(expand ? 'CX.MINIMIZE' :'CX.FULL_SCREEN') |translate">
            <mat-icon [icIcon]="expand ? icFullscreenExit : icFullscreen" size="18px"></mat-icon>
        </button>
        <button type="button" class="mr-2 px-2" mat-button (click)="close()">
            <mat-icon [icIcon]="icClose" size="18px"></mat-icon>
        </button>
    </div>

    <div #chatContainer cdkScrollable class="chat-body overflow-y-auto overflow-x-hidden" infiniteScroll
        netScrollDirective [infiniteScrollUpDistance]="1" [infiniteScrollThrottle]="1000" [infiniteScrollDistance]="0.5"
        [scrollWindow]="false" (scrolledUp)="onScrollUp()" [infiniteScrollDisabled]="infiniteScrollDisabled">

        <div class="w-full flex items-center justify-center mt-2" *ngIf="isLoadingUp">
            <mat-spinner class="mr-2" [diameter]="20"></mat-spinner>{{'GENERAL.LOADING' | translate}}
        </div>

        <net-chat-middle [conversation]="conversation" [isPreview]="true"
            [cxChannelId]="preview?.cxChannel?.cxChannelId"></net-chat-middle>

    </div>

</div>
