import { Action } from '@ngrx/store';
import { ActionNeededCounts, AssignmentCountRequest, Response } from '@core/api';

export enum NotificationActionType {
    GET_NOTIFICATION = '[Notification] GET_NOTIFICATION',
    GET_NOTIFICATION_SUCCESS = '[Notification] GET_NOTIFICATION_SUCCESS',
    GET_NOTIFICATION_ERROR = '[Notification] GET_NOTIFICATION_ERROR',
    INCREASE_NOTIFICATION_COUNT = '[Notification] INCREASE_NOTIFICATION_COUNT',
    DECREASE_NOTIFICATION_COUNT = '[Notification] DECREASE_NOTIFICATION_COUNT',
    UPDATE_NOTIFICATION_COUNT = '[Notification] UPDATE_NOTIFICATION_COUNT',
    GET_LEAD_ASSIGNMENT_COUNT = '[Notification] GET_LEAD_ASSIGNMENT_COUNT',
    GET_LEAD_ASSIGNMENT_COUNT_SUCCESS = '[Notification] GET_LEAD_ASSIGNMENT_COUNT_SUCCESS',
    GET_LEAD_ASSIGNMENT_COUNT_ERROR = '[Notification] GET_LEAD_ASSIGNMENT_COUNT_ERROR',
    RESET_NOTIFICATION_COUNT = '[Notification] RESET_NOTIFICATION_COUNT',
    GET_MAIL_UNREAD_COUNT = '[Notification] GET_MAIL_UNREAD_COUNT',
    GET_MAIL_UNREAD_COUNT_SUCCESS = '[Notification] GET_MAIL_UNREAD_COUNT_SUCCESS',
    GET_MAIL_UNREAD_COUNT_ERROR = '[Notification] GET_MAIL_UNREAD_COUNT_ERROR',
}

export class GetNotification implements Action {
    readonly type = NotificationActionType.GET_NOTIFICATION;
    constructor() { }
}

export class GetNotificationSuccess implements Action {
    readonly type = NotificationActionType.GET_NOTIFICATION_SUCCESS;
    constructor(public payload: ActionNeededCounts) { }
}

export class GetNotificationError implements Action {
    readonly type = NotificationActionType.GET_NOTIFICATION_ERROR;
    constructor(public payload: Response<null>) { }
}

export class IncreaseNotificationCount implements Action {
    readonly type = NotificationActionType.INCREASE_NOTIFICATION_COUNT;
}

export class DecreaseNotificationCount implements Action {
    readonly type = NotificationActionType.DECREASE_NOTIFICATION_COUNT;
}

export class ResetNotificationCount implements Action {
    readonly type = NotificationActionType.RESET_NOTIFICATION_COUNT;
}

export class UpdateNotificationCount implements Action {
    readonly type = NotificationActionType.UPDATE_NOTIFICATION_COUNT;
    constructor(public count: number) {}
}

export class GetLeadAssignmentCount implements Action {
    readonly type = NotificationActionType.GET_LEAD_ASSIGNMENT_COUNT;
    constructor(public payload: AssignmentCountRequest) {}
}

export class GetLeadAssignmentCountSuccess implements Action {
    readonly type = NotificationActionType.GET_LEAD_ASSIGNMENT_COUNT_SUCCESS;
    constructor(public payload: number) {}
}

export class GetLeadAssignmentCountError implements Action {
    readonly type = NotificationActionType.GET_LEAD_ASSIGNMENT_COUNT_ERROR;
    constructor(public payload: Response<null>) {}
}

export class GetMailUnreadCount implements Action {
  readonly type = NotificationActionType.GET_MAIL_UNREAD_COUNT;
  constructor() {}
}

export class GetMailUnreadCountSuccess implements Action {
  readonly type = NotificationActionType.GET_MAIL_UNREAD_COUNT_SUCCESS;
  constructor(public payload: number) {}
}

export class GetMailUnreadCountError implements Action {
  readonly type = NotificationActionType.GET_MAIL_UNREAD_COUNT_ERROR;
  constructor(public payload: Response<null>) {}
}

export type NotificationActions =
  GetNotification |
  GetNotificationSuccess |
  GetNotificationError |
  IncreaseNotificationCount |
  UpdateNotificationCount |
  DecreaseNotificationCount |
  ResetNotificationCount |
  GetLeadAssignmentCount |
  GetLeadAssignmentCountSuccess |
  GetLeadAssignmentCountError |
  GetMailUnreadCount |
  GetMailUnreadCountSuccess |
  GetMailUnreadCountError;
