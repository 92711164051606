import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, DoCheck, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Optional, Output, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { CustomerExperienceCategoriesService, CustomerExperienceCategory } from '@core/api';
import { Subject } from 'rxjs';
import { replaceTurkishCharacters } from '../../utils/replace-turkish-characters';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'net-select-customer-experience-categories',
  templateUrl: './select-customer-experience-categories.component.html',
  styleUrls: ['./select-customer-experience-categories.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectCustomerExperienceCategoriesComponent,
    },
  ],
})
export class SelectCustomerExperienceCategoriesComponent implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<string | string[] | null> {

  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'select-categories';
  describedBy = '';
  stateChanges = new Subject<void>();

  categories: CustomerExperienceCategory[] = [];
  filteredCategories: CustomerExperienceCategory[] = [];
  selectCtrl = new UntypedFormControl();
  searchText = '';

  @Input() multiple: boolean;
  @Input() mode: 'add' | 'edit';
  @Input() defaultSelected = true;
  @Input() searchable = true;
  @Input() tabIndex;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.selectCtrl.disable();
    } else {
      this.selectCtrl.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): string | string[] | null { return this.selectCtrl.value; }
  set value(value: string | string[] | null) {
    this.selectCtrl.setValue(value);
    this.onChange(value);
    this.stateChanges.next();
  }
  private _value: CustomerExperienceCategory[];

  @Output() dropdownValues: EventEmitter<CustomerExperienceCategory[]> = new EventEmitter<CustomerExperienceCategory[]>();

  @Output() isExistDisableValue: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('categoriesSelect') categoriesSelect: MatSelect;

  @HostBinding('id') id = `select-categories-${SelectCustomerExperienceCategoriesComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() { return this.focused || !this.empty; }

  get empty() { return !this.value; }

  private initDefaultOptions(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.categoriesService.search({
        page: 1,
        pageSize: 1000,
        orderBy: 'name',
        orderType: 'ASC',
        filter: {
        }
      })
        .toPromise()
        .then((response) => {
          this.categories = response.data.results;
          this.filteredCategories = response.data.results;

          this.dropdownValues.emit(this.categories);

          this.isExistDisableValueCheck(); // Check if there is any disabled value in the list

          if (this.value?.length > 0) {
            return;
          }

          if (this.defaultSelected && this.filteredCategories.length === 1) {

            this.value = this.filteredCategories.map(c => c.cxCategoryId);

          }

        })
        .finally(() => resolve());
    });
  }

  constructor(
    private categoriesService: CustomerExperienceCategoriesService,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.selectCtrl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.isExistDisableValueCheck();
    });
  }
  ngOnInit() {
    this.initDefaultOptions();
  }

  selectAll() {

    if (!this.multiple) {
      return;
    }

    this.selectCtrl.patchValue(this.filteredCategories.map(category => category.cxCategoryId));
    this.value = this.filteredCategories.map(c => c.cxCategoryId);

  }

  deselectAll() {

    if (!this.multiple) {
      return;
    }

    this.selectCtrl.patchValue(null);
    this.value = [];
  }

  onKey(event) {
    if (!this.multiple && !this.searchable) {
      return;
    }
    const selectedCategories = Array.isArray(this.value) ? this.getFilteredList().filter(category =>
      (this.value as CustomerExperienceCategory[]).map(cat => cat.cxCategoryId).includes(category.cxCategoryId)) : [];
    this.filteredCategories = [...new Set([...this.search(this.getFilteredList(), 'name', event.target.value), ...selectedCategories])];

  }

  search(searchList: any[], searchKey: string, searchText: string) {
    const filter = replaceTurkishCharacters(searchText).toUpperCase();
    return searchList?.filter(option => option[searchKey].toUpperCase().includes(filter));
  }

  openedChange(isOpened: boolean) {
    if (isOpened) {
      const panel = this.categoriesSelect.panel.nativeElement;
      panel.scrollTop = 0;
    }
  }

  onSelectionChange() {
    this.onChange(this.value);
    this.stateChanges.next();
  }

  getFilteredList() {
    return this.filteredCategories.filter(category => {
      if (this.mode === 'add') {
        return category.activeFlag;
      } else {
        return (this.multiple ? this.selectCtrl.value?.includes(category.cxCategoryId) :
          this.selectCtrl.value === category.cxCategoryId) || category.activeFlag;
      }
    });
  }

  isExistDisableValueCheck() {

    const disableItems = this.filteredCategories.filter(category => !category.activeFlag);

    this.isExistDisableValue.emit(
      disableItems.filter(category => {
        if (this.multiple && this.selectCtrl.value?.includes(category.cxCategoryId)) {
          return true;
        } else if (!this.multiple && this.selectCtrl.value === category.cxCategoryId) {
          return true;
        } else {
          return false;
        }
      }).length > 0
    );
  }

  getSelectedValues() {
    const selectedValues = [];

    const controllers = this.selectCtrl.value ?? [];
    controllers.forEach((control) => {
      const selectedValue = this.categories.find((value) => value.cxCategoryId === control);
      if (selectedValue) {
        selectedValues.push(selectedValue);
      }
    });

    return selectedValues?.map((val) => val.name).join(', ');
  }

  onChange = (_: any) => { };
  onTouched = () => { };
  compareWith = (one: string | string, two: string | string) =>
    one && two && one === two
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');

  writeValue(value: string | string[]) {
    this.value = value;
  }

  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

}
