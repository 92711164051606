import { NavigationStart, Router } from '@angular/router';
import {
  Component, ElementRef, HostBinding, HostListener,
  Input, OnDestroy, OnInit, ViewChild
} from '@angular/core';

import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LoginInfo, MailService, MailSignalRService, User } from '@core/api';
import { getUser, hasUserPermission } from '@core/store';

import icClose from '@iconify/icons-ic/twotone-close';
import icOpenInNew from '@iconify/icons-ic/twotone-open-in-new';
import icFullscreen from '@iconify/icons-ic/twotone-fullscreen';
import icFullscreenExit from '@iconify/icons-ic/twotone-fullscreen-exit';

import { MailPreviewModel } from './mail-preview.component.model';
import { HtmlViewerComponent } from '../../shared/components/html-viewer/html-viewer.component';
import { MailPreviewComponentService } from './mail-preview.component.service';
import { fadeInUp400ms } from '../../shared/animations/fade-in-up.animation';
import { filter } from 'rxjs/operators';
import { Permission } from '@core/auth/auth.enum';

@UntilDestroy()
@Component({
  selector: 'net-mail-preview[preview][index]',
  templateUrl: './mail-preview.component.html',
  styleUrls: ['./mail-preview.component.scss'],
  animations: [fadeInUp400ms]
})
export class MailPreviewComponent implements OnInit, OnDestroy {
  private offsetX = 0;
  private offsetY = 0;
  private initialX = 0;
  private initialY = 0;

  private boxTop = 0;
  private boxLeft = 0;

  hasMailMenu: boolean;

  user: User;

  icClose = icClose;
  icOpenInNew = icOpenInNew;
  icFullscreen = icFullscreen;
  icFullscreenExit = icFullscreenExit;

  get element() {
    return this.elementRef.nativeElement;
  }

  @Input() preview: MailPreviewModel;
  @Input() index: number;

  @ViewChild(HtmlViewerComponent, { static: true }) htmlViewer: HtmlViewerComponent;

  @HostBinding('style.z-index') zindex = 100;
  @HostBinding('class.preview-expanded') expand = false;
  @HostBinding('class.preview-dragging') dragging = false;

  @HostListener('click') onClick() {
    this.zindex = this.expand ? 300 : 200;
  }

  @HostListener('dblclick') onDoubleClick() {
    this.toggleExpand();
  }

  @HostListener('document:click', ['$event']) onDocumentClick(event) {
    // When the user clicks outside the element, reset the z-index
    if (!this.expand && false === this.element.contains(event.target)) {
      this.zindex = 100 + this.index;
    }
  }

  @HostListener('window:mouseup', ['$event']) onWindowMouseUp() {
    if (!this.dragging) {
      return;
    }

    // Disable dragging
    this.dragging = false;
  }

  @HostListener('window:keydown', ['$event']) onWindowKeydown($event) {
    if (this.expand && $event.key === 'Escape') {
      this.toggleExpand();
    }
  }

  @HostListener('window:mousemove', ['$event']) onWindowMouseMove(event) {
    if (!this.dragging) {
      return;
    }

    // Calculate the distance moved by the cursor
    const distanceX = event.clientX - this.initialX;
    const distanceY = event.clientY - this.initialY;

    // Calculate the new position of the element based on the initial position and cursor movement
    const top = this.offsetY + distanceY;
    const left = this.offsetX + distanceX;

    // Prevent the element from moving outside the viewport
    if (top <= 0 || left <= 0) { return; }

    // Set the new position of the element
    this.element.style.top = `${top}px`;
    this.element.style.left = `${left}px`;

    // Remove initial positions
    this.element.style.removeProperty('right');
    this.element.style.removeProperty('bottom');
  }

  constructor(
    private store: Store,
    private router: Router,
    private elementRef: ElementRef<HTMLElement>,
    private mailPreviewService: MailPreviewComponentService,
    private mailService: MailService
  ) {

    this.store.select(getUser).pipe(untilDestroyed(this)).subscribe(user => this.user = user);

    this.store.select(hasUserPermission(Permission.MAIL_MAIN_MENU))
      .pipe(untilDestroyed(this)).subscribe(response => this.hasMailMenu = response);
  }

  onMouseDown(event) {
    // Store the initial click position
    this.initialX = event.clientX;
    this.initialY = event.clientY;

    // Store the initial element position
    this.offsetX = this.element.offsetLeft;
    this.offsetY = this.element.offsetTop;

    // Enable dragging
    this.dragging = true;

    // Increase the z-index when the user clicks on the element
    this.zindex = this.expand ? 300 : 200;
  }

  ngOnInit() {
    this.zindex = 100 + this.index;


    // Prepare stack item count by screen width
    let stackItem = 1;

    if (window.innerWidth > 1000) {
      stackItem = 2;
    }

    if (window.innerWidth > 1500) {
      stackItem = 3;
    }

    // Calculate initial position to stack previews
    const row = Math.floor(this.index / stackItem);
    const column = this.index % stackItem;

    const width = this.element.offsetWidth;
    const height = this.element.offsetHeight;

    const right = column * width + (column * 18) + (row * 18) + 24 + 56;
    const bottom = row * (row === 0 ? height : height * 0.05) + (row * 18) + 24;

    this.element.style.right = `${right}px`;
    this.element.style.bottom = `${bottom}px`;
  }

  open() {
    this.close();

    window.open(
      this.router.serializeUrl(this.router.createUrlTree(['/mail', this.preview.folderId, this.preview.mailId])),
      '_blank'
    );
  }

  close() {
    this.mailPreviewService.remove(this.preview);
  }

  toggleExpand() {
    // Make expanded
    if (false === this.expand) {
      this.expand = true;
      this.zindex = 300;

      this.boxTop = this.element.offsetTop;
      this.boxLeft = this.element.offsetLeft;

      this.element.style.removeProperty('top');
      this.element.style.removeProperty('left');

      this.htmlViewer.component.iFrameResizer.resize();

      return;
    }

    // Return to box view
    this.expand = false;
    this.zindex = 100 + this.index;

    this.element.style.top = `${this.boxTop}px`;
    this.element.style.left = `${this.boxLeft}px`;

    this.htmlViewer.component.iFrameResizer.resize();
  }

  ngOnDestroy() { }
}
