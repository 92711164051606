import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component, DoCheck, ElementRef, EventEmitter, HostBinding, Input,
  OnDestroy, OnInit, Optional, Output, Self, ViewChild
} from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { TableCustomFilter, TableFilter } from 'src/app/shared/components/mat-table-customized-filters/table-custom-filter.model';
import { TableCustomFilterService } from 'src/app/shared/components/mat-table-customized-filters/table-custom-filter.service';
import { replaceTurkishCharacters } from 'src/app/shared/utils/replace-turkish-characters';

@Component({
  selector: 'net-select-survey-mail-contact-filter-set',
  templateUrl: './select-survey-mail-contact-filter-set.component.html',
  styleUrls: ['./select-survey-mail-contact-filter-set.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectSurveyMailContactFilterSetComponent
    }
  ]
})
export class SelectSurveyMailContactFilterSetComponent implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<string>  {

  static nextId = 0;
  stateChanges = new Subject<void>();
  selectCtrl = new UntypedFormControl();
  focused = false;
  errorState = false;
  describedBy = '';
  controlType = 'select-contact-filter-set';

  contactList: TableCustomFilter<TableFilter>[] = [];
  filteredContactList: TableCustomFilter<TableFilter>[] = [];

  @Input() tableId = 'contact';
  @Input() isDisabled = false;
  @Input() tabIndex;
  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.selectCtrl.disable();
    } else {
      this.selectCtrl.enable();
    }
    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): string { return this.selectCtrl.value; }
  set value(value: string) {
    this.selectCtrl.setValue(value);
    this.onChange(value);
    this.stateChanges.next();
  }

  @Output() selectedFilterSet = new EventEmitter<TableCustomFilter<TableFilter>>();

  @ViewChild('contactFilterSetSelect') contactFilterSetSelect: MatSelect;
  @HostBinding('id') id = `select-contact-filter-set-${SelectSurveyMailContactFilterSetComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() { return this.focused || !this.empty; }

  get empty() { return !this.value; }


  constructor(
    private tableCustomFilterService: TableCustomFilterService,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl
  ) {
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

  }

  ngOnInit(): void {

    this.getContactsFilterList();
  }

  getContactsFilterList() {
    this.tableCustomFilterService.getFilters(this.tableId)
      .pipe(
        map((filters: TableCustomFilter<TableFilter>[]) => {
          return filters.map((filter: TableCustomFilter<TableFilter>) => {
            if (filter.editable === undefined) {
              filter.editable = true;
            }

            if (filter.erasable === undefined) {
              filter.erasable = true;
            }

            return filter;
          });
        })).subscribe((response) => {
          this.contactList = response.filter((tableFilter) => {
            const leadContact = tableFilter.filter?.filter?.leadContact;
            return !leadContact;
          });
          this.filteredContactList = this.contactList.sort((a, b) => a.name.localeCompare(b.name));

          if (this.value) {
            this.selectedFilterSet.emit(
              this.contactList.find(x => x.id === this.value)
            );
          }
          // Kullanıcının görebildiği tek kontak listesi var ise defaultta seçili gelecektir
          if (this.contactList.length === 1) {
            this.selectCtrl.patchValue(this.contactList[0].id);
            this.value = this.contactList[0].id;
            this.selectedFilterSet.emit(this.contactList[0]);
          }
        });
  }

  onKey(event) {
    this.filteredContactList = this.search(this.contactList, 'name', event.target.value);

  }

  search(searchList: any[], searchKey: string, searchText: string) {
    const filter = replaceTurkishCharacters(searchText).toUpperCase();
    return searchList?.filter(option => option[searchKey].toUpperCase().includes(filter));
  }


  onSelectionChange() {
    this.onChange(this.value);
    this.stateChanges.next();
  }

  openedChange(isOpened: boolean) {
    if (isOpened) {
      const panel = this.contactFilterSetSelect.panel.nativeElement;
      panel.scrollTop = 0;
    }
  }


  compareWith = (one: string, two: string) => one && two && one === two;
  onChange = (_: any) => { };
  selectionChange = (_: any) => { };
  onTouched = () => { };
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
  writeValue = (value: string) => {
    this.value = value;
    this.selectCtrl.setValue(value);
  }

  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

}
