import { TextMaskModule } from 'angular2-text-mask';
import { FileSaverModule } from 'ngx-filesaver';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ToastrModule } from 'ngx-toastr';
import { NgxUploaderModule } from 'ngx-uploader';
import { NgxMaskModule, IConfig, NgxMaskPipe } from 'ngx-mask';

import { GoogleMapsModule } from '@angular/google-maps';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { IconModule } from '@visurel/iconify-angular';

import {
  InputOfferAirCargoComponent
} from '../lead/lead-detail/lead-detail-offer-form-ngf/input-offer-air-cargo/input-offer-air-cargo.component';
import { AccountNameDialogComponent } from './components/account-name-dialog/account-name-dialog.component';
import { ActivityDocumentComponent } from './components/activity-document/activity-document.component';
import { ActivityFormDialogComponent } from './components/activity-form-dialog/activity-form-dialog.component';
import { ActivityFormComponent } from './components/activity-form/activity-form.component';
import { ActivityRelationBoxComponent } from './components/activity-relation-box/activity-relation-box.component';
import { AddressFormDialogComponent } from './components/address-form-dialog/address-form-dialog.component';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { AssignmentDocumentComponent } from './components/assignment-document/assignment-document.component';
import { NetColorPickerComponent } from './components/color-picker/net-color-picker.component';
import { ContactFormDialogComponent } from './components/contact-form-dialog/contact-form-dialog.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { ContactRelationBoxComponent } from './components/contact-relation-box/contact-relation-box.component';
import { CustomerSearchDialogComponent } from './components/customer-search-dialog/customer-search-dialog.component';
import { DocumentHistoryComponent } from './components/document-history/document-history.component';
import { DocumentListComponent } from './components/document-list/document-list.component';
import { DocumentUploadComponent } from './components/document-upload/document-upload.component';
import {
  FinancialInquiryFormDialogComponent
} from './components/financial-inquiry-form-dialog/financial-inquiry-form-dialog.component';
import {
  FinancialInquiryUploadComponent
} from './components/financial-inquiry-upload/financial-inquiry-upload.component';
import { FullSpinnerComponent } from './components/full-spinner/full-spinner.component';
import {
  InputActivityAssignmentComponent
} from './components/input-activity-assignment/input-activity-assignment.component';
import { InputCompetitorComponent } from './components/input-competitor/input-competitor.component';
import { InputCustomerAddressComponent } from './components/input-customer-address/input-customer-address.component';
import { InputCustomerContactComponent } from './components/input-customer-contact/input-customer-contact.component';
import { InputCustomerEmailsComponent } from './components/input-customer-emails/input-customer-emails.component';
import { InputCustomerPhonesComponent } from './components/input-customer-phones/input-customer-phones.component';
import { InputHashtagComponent } from './components/input-hashtag/input-hashtag.component';
import {
  InputLeadContactPhoneComponent
} from './components/input-lead-contact-phone/input-lead-contact-phone.component';
import { InputMoneyComponent } from './components/input-money/input-money.component';
import { InputNumberComponent } from './components/input-number/input-number.component';
import {
  InputOpportunityServicesComponent
} from './components/input-opportunity-services/input-opportunity-services.component';
import { InputOpportunityServicesPipe } from './components/input-opportunity-services/input-opportunity-services.pipe';
import { InputParticipantComponent } from './components/input-participant/input-participant.component';
import { InputSliderComponent } from './components/input-slider/input-slider.component';
import { InputSpecialDayComponent } from './components/input-special-day/input-special-day.component';
import { InputTimeComponent } from './components/input-time/input-time.component';
import { LeadDraftInfoDialogComponent } from './components/lead-draft-info-dialog/lead-draft-info-dialog.component';
import { LeadDraftInfoComponent } from './components/lead-draft-info/lead-draft-info.component';
import { MatHeaderFilterComponent } from './components/mat-header-filter/mat-header-filter.component';
import { MatTableColumnFilterComponent } from './components/mat-table-column-filter/mat-table-column-filter.component';
import {
  MatTableCustomizedFiltersComponent
} from './components/mat-table-customized-filters/mat-table-customized-filters.component';
import {
  MultiSelectActivityStatusComponent
} from './components/multi-select-activity-status/multi-select-activity-status.component';
import {
  MultiSelectActivityTypeComponent
} from './components/multi-select-activity-type/multi-select-activity-type.component';
import {
  MultiSelectSalesRouteComponent
} from './components/multi-select-sales-route/multi-select-sales-route.component';
import {
  MultiSelectSystemUserComponent
} from './components/multi-select-system-user/multi-select-system-user.component';
import { NoteLogsComponent } from './components/note-logs/note-logs.component';
import { NoteFormDialogComponent } from './components/notes/note-form-dialog/note-form-dialog.component';
import { NoteSummaryPipePipe } from './components/notes/note-summary-pipe.pipe';
import { NotesComponent } from './components/notes/notes.component';
import {
  OfferConfirmFormDialogComponent
} from './components/offer-confirm-form-dialog/offer-confirm-form-dialog.component';
import {
  OfferRejectionReasonDialogComponent
} from './components/offer-rejection-reason-dialog/offer-rejection-reason-dialog.component';
import {
  OfferRevisionListDialogComponent
} from './components/offer-revision-list-dialog/offer-revision-list-dialog.component';
import {
  OpportunityCommunicationPanelComponent
} from './components/opportunity-communication-panel/opportunity-communication-panel.component';
import { OpportunityDocumentComponent } from './components/opportunity-document/opportunity-document.component';
import { OpportunityFormDialogComponent } from './components/opportunity-form-dialog/opportunity-form-dialog.component';
import { OpportunityFormComponent } from './components/opportunity-form/opportunity-form.component';
import {
  OpportunityInitialsDialogComponent
} from './components/opportunity-initials-dialog/opportunity-initials-dialog.component';
import {
  OpportunityRelationBoxComponent
} from './components/opportunity-relation-box/opportunity-relation-box.component';
import { OpportunityStepperComponent } from './components/opportunity-stepper/opportunity-stepper.component';
import { PageLayoutContentDirective } from './components/page-layout/page-layout-content.directive';
import { PageLayoutHeaderDirective } from './components/page-layout/page-layout-header.directive';
import { PageLayoutComponent } from './components/page-layout/page-layout.component';
import { PopoverComponent } from './components/popover/popover.component';
import {
  ProductionAccountCodeDialogComponent
} from './components/production-account-code-dialog/production-account-code-dialog.component';
import { ProfilePhotoComponent } from './components/profile-photo/profile-photo.component';
import { ProgressButtonComponent } from './components/progress-button/progress-button.component';
import { QuickActionComponent } from './components/quick-action/quick-action.component';
import {
  SalesRouteDefinitionsDialogComponent
} from './components/sales-route-definitions-dialog/sales-route-definitions-dialog.component';
import { SelectAccountComponent } from './components/select-account/select-account.component';
import {
  SelectActivityPdfTemplateComponent
} from './components/select-activity-pdf-template/select-activity-pdf-template.component';
import { SelectActivityPurposeComponent } from './components/select-activity-purpose/select-activity-purpose.component';
import { SelectActivityStatusComponent } from './components/select-activity-status/select-activity-status.component';
import { SelectActivityTypeComponent } from './components/select-activity-type/select-activity-type.component';
import {
  SelectActivityVideoConferenceToolComponent
} from './components/select-activity-video-conference-tool/select-activity-video-conference-tool.component';
import { SelectAirLineComponent } from './components/select-air-line/select-air-line.component';
import { SelectAirPortComponent } from './components/select-air-port/select-air-port.component';
import {
  SelectAssignmentPriorityComponent
} from './components/select-assignment-priority/select-assignment-priority.component';
import { SelectCarrierUnitComponent } from './components/select-carrier-unit/select-carrier-unit.component';
import { SelectCarryingFeatureComponent } from './components/select-carrying-feature/select-carrying-feature.component';
import { SelectCityComponent } from './components/select-city/select-city.component';
import { SelectClearanceComponent } from './components/select-clearance/select-clearance.component';
import {
  SelectContactRoleGroupComponent
} from './components/select-contact-role-group/select-contact-role-group.component';
import { SelectCountryComponent } from './components/select-country/select-country.component';
import { SelectCountyComponent } from './components/select-county/select-county.component';
import { SelectCurrencyComponent } from './components/select-currency/select-currency.component';
import { SelectFuelCityComponent } from './components/select-fuel-city/select-fuel-city.component';
import { SelectFuelCountyComponent } from './components/select-fuel-county/select-fuel-county.component';
import { SelectLanguageComponent } from './components/select-language/select-language.component';
import { SelectHomepageComponent } from './components/select-homepage/select-homepage.component';
import { SelectMeetingStatusComponent } from './components/select-meeting-status/select-meeting-status.component';
import { SelectNeighborhoodComponent } from './components/select-neighborhood/select-neighborhood.component';
import {
  SelectNotificationTemplateComponent
} from './components/select-notification-template/select-notification-template.component';
import { SelectOfferSearchableComponent } from './components/select-offer-searchable/select-offer-searchable.component';
import { SelectOfferUnitComponent } from './components/select-offer-unit/select-offer-unit.component';
import { SelectOfferComponent } from './components/select-offer/select-offer.component';
import {
  SelectOpportunitySearchableComponent
} from './components/select-opportunity-searchable/select-opportunity-searchable.component';
import {
  SelectOpportunityServiceUnitComponent
} from './components/select-opportunity-service-unit/select-opportunity-service-unit.component';
import {
  SelectOpportunityStatusComponent
} from './components/select-opportunity-status/select-opportunity-status.component';
import { SelectOpportunityTypeComponent } from './components/select-opportunity-type/select-opportunity-type.component';
import { SelectOpportunityComponent } from './components/select-opportunity/select-opportunity.component';
import { SelectOrganizationComponent } from './components/select-organization/select-organization.component';
import {
  SelectOrganizationalStructureComponent
} from './components/select-organizational-structure/select-organizational-structure.component';
import { SelectPaymentTermComponent } from './components/select-payment-term/select-payment-term.component';
import {
  SelectSalesCountyGroupComponent
} from './components/select-sales-county-group/select-sales-county-group.component';
import {
  SelectSalesOrganizationComponent
} from './components/select-sales-organization/select-sales-organization.component';
import { SelectSalesRouteComponent } from './components/select-sales-route/select-sales-route.component';
import { SelectSalutationComponent } from './components/select-salutation/select-salutation.component';
import { SelectSeaLineComponent } from './components/select-sea-line/select-sea-line.component';
import { SelectSeaPortComponent } from './components/select-sea-port/select-sea-port.component';
import { SelectSectionComponent } from './components/select-section/select-section.component';
import { SelectServiceUnitComponent } from './components/select-service-unit/select-service-unit.component';
import { SelectServiceUnitPipe } from './components/select-service-unit/select-service-unit.pipe';
import { SelectServiceComponent } from './components/select-service/select-service.component';
import { SelectServicePipe } from './components/select-service/select-service.pipe';
import { SelectSystemUserInfoComponent } from './components/select-system-user-info/select-system-user-info.component';
import { SelectTaxOfficeComponent } from './components/select-tax-office/select-tax-office.component';
import { SelectTimeComponent } from './components/select-time/select-time.component';
import { SelectUserComponent } from './components/select-user/select-user.component';
import {
  SelectValueAddedServiceComponent
} from './components/select-value-added-service/select-value-added-service.component';
import { ContainerDirective } from './directives/container.directive';
import { FocusOnShowDirective } from './directives/focus-on-show.directive';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { ScrollbarDirective } from './directives/scrollbar.directive';
import { SpaceControlDirective } from './directives/space-control.directive';
import { UpperCaseDirective } from './directives/upper-case.directive';
import { MaterialModule } from './material.module';
import { ActivityParticipantsPipe } from './pipes/activity-participants.pipe';
import { ColorFadePipe } from './pipes/color-fade.pipe';
import { CurrencyReplacePipe } from './pipes/currency-replace.pipe';
import { CurrencyTypeCodePipe } from './pipes/currency-type-code.pipe';
import { CustomNumberPipe } from './pipes/custom-number.pipe';
import { CustomerEmailsPipe } from './pipes/customer-emails.pipe';
import { CustomerPhonesPipe } from './pipes/customer-phones.pipe';
import { DateDiffPipe } from './pipes/date-diff.pipe';
import { DynamicTranslatePipe } from './pipes/dynamic-translate.pipe';
import { FormatFileSizePipe } from './pipes/format-file-size.pipe';
import { IsAfterPipe } from './pipes/is-after.pipe';
import { IsBeforePipe } from './pipes/is-before.pipe';
import { IsSamePipe } from './pipes/is-same.pipe';
import { MomentFormatPipe } from './pipes/moment-format.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { OrganizationLogoPipe } from './pipes/organization-logo.pipe';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { SecureImagePipe } from './pipes/secure-image.pipe';
import { ShipmentProfileIntervalPipe } from './pipes/shipment-profile-interval.pipe';
import { SortByPipe } from './pipes/sort-by.pipe';
import { StripHtmlPipe } from './pipes/strip-html.pipe';
import { IncludesPipe } from './pipes/includes.pipe';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { InputEditorCkComponent } from './components/input-editor-ck/input-editor-ck.component';
import { InputDatetimeComponent } from './components/input-datetime/input-datetime.component';
import { InputDynamicFieldValuesComponent } from './components/input-dynamic-field-values/input-dynamic-field-values.component';
import { SelectActivityComponent } from './components/select-activity/select-activity.component';
import { DynamicFieldPicklistFilterPipe } from './components/input-dynamic-field-values/dynamic-field-picklist-filter.pipe';
import { NormalizeDecimalSeparatorPipe } from './pipes/normalize-decimal-seperator.pipe';
import { ReportCustomizedFiltersComponent } from './components/report-customized-filters/report-customized-filters.component';
import { InputDynamicFieldPicklistComponent } from './components/input-dynamic-field-picklist/input-dynamic-field-picklist.component';
import { MultiAccountDialogComponent } from './components/multi-account-dialog/multi-account-dialog.component';
import { InputOpportunityServicesOneSelectPipe } from './components/input-opportunity-services/input-opportunity-services-auto-select.pipe';
import { AccountCommunicationPanelComponent } from './components/account-communication/account-communication.component';
import { MultiSelectUserComponent } from './components/multi-select-user/multi-select-user.component';
import { InputUserMentionComponent } from '../board/dialogs/board-detail-form-dialog/input-user-mention/input-user-mention.component';
import { InputOpportunityCustomListValuesComponent } from './components/input-opportunity-custom-list/input-opportunity-custom-list-values.component';
import { InputOpportunityCustomListRowComponent } from './components/input-opportunity-custom-list/input-opportunity-custom-list-row/input-opportunity-custom-list-row.component';
import { MatTablePaginatorComponent } from './components/mat-table-paginator/mat-table-paginator.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgModelChangeDebouncedDirective } from './directives/ng-model-changed-debounce.directive';
import { SortByHashtagOrderPipe } from './pipes/sort-by-number.pipe';
import { NgModelValidationDirective } from './directives/ng-model-validation.directive';
import { SelectBoardComponent } from './components/select-board/select-board.component';
import { SelectBoardCardComponent } from './components/select-board-card/select-board-card.component';
import { SelectBoardSectionComponent } from './components/select-board-section/select-board-section.component';
import { InputAssignmentComponent } from './components/input-assignment/input-assignment.component';
import { AssignmentFormComponent } from './components/assignment-form/assignment-form.component';
import { BoardCardFormComponent } from './components/board-card-form/board-card-form.component';
import { MailLinkListComponent } from './components/mail-link-list/mail-link-list.component';
import { HtmlViewerComponent } from './components/html-viewer/html-viewer.component';
import { MailAttachmentComponent } from './components/mail-attachment/mail-attachment.component';
import { InputContactComponent } from './components/input-contact/input-contact.component';
import { InputLeadDraftComponent } from './components/input-lead-draft/input-lead-draft.component';
import { MailCircleAvatarComponent } from './components/mail-circle-avatar/mail-circle-avatar.component';
import { AutocompletePositionDirective } from './directives/mat-autocomplete-update-panel-position.directive';
import { SwiperDirective } from './directives/swiper.directive';
import { SelectContactComponent } from './components/select-contact/select-contact.component';
import { MultiSelectCcUsersComponent } from './components/multi-select-cc-users/multi-select-cc-users.component';
import { ConfirmRescheduleDialogComponent } from './components/lead-draft-info/dialogs/confirm-reschedule-dialog/confirm-reschedule-dialog.component';
import { NetMultiSelectDocumentComponent } from './components/net-multi-select-document/net-multi-select-document.component';
import { OpportunitySendingMailDialogComponent } from './components/opportunity-form/opportunity-sending-mail/opportunity-sending-mail-dialog.component';
import { SelectRouteLevelsComponent } from './components/select-route-levels/select-route-levels.component';
import { SelectRoleComponent } from './components/select-role/select-role.component';
import { SelectPermissionComponent } from './components/select-permission/select-permission.component';
import { SelectCustomerExperienceChannelTypeComponent } from './components/select-customer-experience-channel-type/select-customer-experience-channel-type.component';
import { SelectCustomerExperienceChannelComponent } from './components/select-customer-experience-channel/select-customer-experience-channel.component';
import { MatTableHeaderComponent } from './components/mat-table-header/mat-table-header.component';
import { SelectCustomerExperienceCategoriesComponent } from './components/select-customer-experience-categories/select-customer-experience-categories.component';
import { SelectCustomerExperienceChartComponent } from './components/select-customer-experience-chart/select-customer-experience-chart.component';
import { CreateLeadlessCustomerWizardDialogComponent } from './components/customer-search-dialog/create-leadless-customer-wizard-dialog/create-leadless-customer-wizard-dialog.component';
import { ScrollDirective } from './directives/scroll.directive';
import { ChatMiddleComponent } from '../customer-experience/components/chat-middle/chat-middle.component';
import { ChatMessageComponent } from '../customer-experience/components/chat-message/chat-message.component';
import { TicketLinkListComponent } from './components/ticket-link-list/ticket-link-list.component';
import { LeadDraftInfoActivitiesComponent } from './components/lead-draft-info-activities/lead-draft-info-activities.component';
import { SelectCustomerExperienceChannelItemsPipe } from './pipes/select-customer-experience-channel-items.pipe';
import { MatHeaderFilterClientComponent } from './components/mat-header-filter-client/mat-header-filter-client.component';
import { DashboardThemeCardComponent } from '../custom-report-table/components/dashboard-theme-card/dashboard-theme-card.component';
import { OpportunityApprovalsComponent } from './components/opportunity-approvals/opportunity-approvals.component';
import { OpportunityApprovalRequestDialogComponent } from './components/opportunity-approval-request-dialog/opportunity-approval-request-dialog.component';
import { SelectCoordinatorshipComponent } from './components/select-coordinatorship/select-coordinatorship.component';
import { CustomListValuesComponent } from './components/custom-list-values/custom-list-values.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    PopoverComponent,
    PageLayoutComponent,
    PageLayoutHeaderDirective,
    PageLayoutContentDirective,
    AutocompletePositionDirective,
    ContainerDirective,
    ScrollbarDirective,
    SwiperDirective,
    IsAfterPipe,
    IsBeforePipe,
    IsSamePipe,
    DateDiffPipe,
    StripHtmlPipe,
    IncludesPipe,
    MomentFormatPipe,
    ColorFadePipe,
    ActivityParticipantsPipe,
    CustomerEmailsPipe,
    CustomerPhonesPipe,
    NoteSummaryPipePipe,
    CurrencyTypeCodePipe,
    NotesComponent,
    NoteLogsComponent,
    NoteFormDialogComponent,
    OrganizationLogoPipe,
    ProgressButtonComponent,
    QuickActionComponent,
    ContactFormComponent,
    ContactFormDialogComponent,
    ContactRelationBoxComponent,
    AddressFormComponent,
    AddressFormDialogComponent,
    ActivityFormComponent,
    ActivityFormDialogComponent,
    ActivityRelationBoxComponent,
    OpportunityFormComponent,
    OpportunityFormDialogComponent,
    OpportunityRelationBoxComponent,
    AlertDialogComponent,
    CustomerSearchDialogComponent,
    InputEditorCkComponent,
    InputCompetitorComponent,
    InputCustomerEmailsComponent,
    InputCustomerPhonesComponent,
    InputLeadContactPhoneComponent,
    InputParticipantComponent,
    InputOpportunityServicesComponent,
    InputOpportunityCustomListValuesComponent,
    InputOpportunityCustomListRowComponent,
    InputHashtagComponent,
    InputMoneyComponent,
    InputSliderComponent,
    InputSpecialDayComponent,
    InputTimeComponent,
    InputCustomerContactComponent,
    SelectTimeComponent,
    SelectCountryComponent,
    SelectLanguageComponent,
    SelectHomepageComponent,
    SelectSectionComponent,
    SelectCityComponent,
    SelectCountyComponent,
    SelectOpportunityTypeComponent,
    SelectNeighborhoodComponent,
    SelectAccountComponent,
    SelectContactComponent,
    SelectOrganizationComponent,
    SelectSalutationComponent,
    SelectActivityPurposeComponent,
    SelectSalesRouteComponent,
    SelectClearanceComponent,
    SelectContactRoleGroupComponent,
    SelectOfferComponent,
    SelectOpportunityComponent,
    SelectContactRoleGroupComponent,
    SelectUserComponent,
    SelectSystemUserInfoComponent,
    SelectSalesCountyGroupComponent,
    FullSpinnerComponent,
    SalesRouteDefinitionsDialogComponent,
    SelectSeaPortComponent,
    SelectAirPortComponent,
    SelectOfferUnitComponent,
    SelectCarryingFeatureComponent,
    SelectCarrierUnitComponent,
    SelectValueAddedServiceComponent,
    SelectAirLineComponent,
    InputOfferAirCargoComponent,
    SelectPaymentTermComponent,
    SelectSeaLineComponent,
    ActivityDocumentComponent,
    AssignmentDocumentComponent,
    OfferRejectionReasonDialogComponent,
    OfferRevisionListDialogComponent,
    SelectFuelCityComponent,
    SelectFuelCountyComponent,
    FormatFileSizePipe,
    OrderByPipe,
    InputActivityAssignmentComponent,
    SelectServiceUnitComponent,
    SelectServiceComponent,
    SelectServicePipe,
    SelectServiceUnitPipe,
    SpaceControlDirective,
    SelectActivityPdfTemplateComponent,
    SelectOpportunityServiceUnitComponent,
    SelectSalesOrganizationComponent,
    SelectNotificationTemplateComponent,
    SelectOrganizationalStructureComponent,
    InputCustomerAddressComponent,
    NumbersOnlyDirective,
    NgModelChangeDebouncedDirective,
    NgModelValidationDirective,
    MultiSelectSystemUserComponent,
    MultiSelectUserComponent,
    MultiSelectCcUsersComponent,
    MultiSelectSalesRouteComponent,
    MultiSelectActivityStatusComponent,
    MultiSelectActivityTypeComponent,
    OpportunityCommunicationPanelComponent,
    AccountCommunicationPanelComponent,
    FinancialInquiryFormDialogComponent,
    SelectTaxOfficeComponent,
    OpportunityStepperComponent,
    FinancialInquiryUploadComponent,
    LeadDraftInfoComponent,
    ConfirmRescheduleDialogComponent,
    LeadDraftInfoDialogComponent,
    ShipmentProfileIntervalPipe,
    SearchFilterPipe,
    DynamicTranslatePipe,
    MatHeaderFilterComponent,
    DocumentListComponent,
    DocumentHistoryComponent,
    DocumentUploadComponent,
    OpportunityDocumentComponent,
    SelectActivityStatusComponent,
    SelectActivityTypeComponent,
    OpportunityInitialsDialogComponent,
    AccountNameDialogComponent,
    SelectActivityVideoConferenceToolComponent,
    FocusOnShowDirective,
    OfferConfirmFormDialogComponent,
    SortByPipe,
    SortByHashtagOrderPipe,
    SelectCurrencyComponent,
    ProductionAccountCodeDialogComponent,
    SelectAssignmentPriorityComponent,
    NetColorPickerComponent,
    SelectOpportunityStatusComponent,
    SelectOfferSearchableComponent,
    SelectOpportunitySearchableComponent,
    InputOpportunityServicesPipe,
    InputOpportunityServicesOneSelectPipe,
    ProfilePhotoComponent,
    SecureImagePipe,
    SelectMeetingStatusComponent,
    CurrencyReplacePipe,
    CustomNumberPipe,
    MatTableCustomizedFiltersComponent,
    MatTableColumnFilterComponent,
    MatTablePaginatorComponent,
    InputNumberComponent,
    UpperCaseDirective,
    InputDynamicFieldValuesComponent,
    InputDatetimeComponent,
    SelectActivityComponent,
    DynamicFieldPicklistFilterPipe,
    NormalizeDecimalSeparatorPipe,
    ReportCustomizedFiltersComponent,
    InputDynamicFieldPicklistComponent,
    MultiAccountDialogComponent,
    InputUserMentionComponent,
    SelectBoardComponent,
    SelectBoardCardComponent,
    SelectBoardSectionComponent,
    InputAssignmentComponent,
    AssignmentFormComponent,
    BoardCardFormComponent,
    MailLinkListComponent,
    HtmlViewerComponent,
    MailAttachmentComponent,
    InputContactComponent,
    InputLeadDraftComponent,
    MailCircleAvatarComponent,
    NetMultiSelectDocumentComponent,
    OpportunitySendingMailDialogComponent,
    SelectRouteLevelsComponent,
    SelectRoleComponent,
    SelectPermissionComponent,
    CreateLeadlessCustomerWizardDialogComponent,
    SelectCustomerExperienceChannelTypeComponent,
    SelectCustomerExperienceChannelComponent,
    MatTableHeaderComponent,
    SelectCustomerExperienceCategoriesComponent,
    SelectCustomerExperienceChartComponent,
    CreateLeadlessCustomerWizardDialogComponent,
    ScrollDirective,
    ChatMiddleComponent,
    ChatMessageComponent,
    TicketLinkListComponent,
    LeadDraftInfoActivitiesComponent,
    SelectCustomerExperienceChannelItemsPipe,
    MatHeaderFilterClientComponent,
    DashboardThemeCardComponent,
    OpportunityApprovalsComponent,
    OpportunityApprovalRequestDialogComponent,
    SelectCoordinatorshipComponent,
    CustomListValuesComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    MaterialModule,
    IconModule,
    ToastrModule.forRoot({
      countDuplicates: true,
      preventDuplicates: true
    }),
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    CKEditorModule,
    NgxUploaderModule,
    TextMaskModule,
    NgxSkeletonLoaderModule,
    FileSaverModule,
    GoogleMapsModule,
    OverlayModule,
    NgxMaskModule.forRoot(options),
    InfiniteScrollModule,
  ],
  exports: [
    TextMaskModule,
    CommonModule,
    TranslateModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    IconModule,
    FlexLayoutModule,
    NgxSkeletonLoaderModule,
    InfiniteScrollModule,
    OverlayModule,
    PopoverComponent,
    PageLayoutComponent,
    PageLayoutHeaderDirective,
    PageLayoutContentDirective,
    AutocompletePositionDirective,
    ContainerDirective,
    ScrollbarDirective,
    SwiperDirective,
    IsAfterPipe,
    IsBeforePipe,
    IsSamePipe,
    DateDiffPipe,
    MomentFormatPipe,
    ColorFadePipe,
    StripHtmlPipe,
    ActivityParticipantsPipe,
    CustomerEmailsPipe,
    CustomerPhonesPipe,
    DynamicTranslatePipe,
    CurrencyTypeCodePipe,
    NotesComponent,
    NoteLogsComponent,
    NoteFormDialogComponent,
    OrganizationLogoPipe,
    ProgressButtonComponent,
    QuickActionComponent,
    ContactFormComponent,
    ContactRelationBoxComponent,
    AddressFormComponent,
    ActivityFormComponent,
    ActivityRelationBoxComponent,
    OpportunityFormComponent,
    OpportunityRelationBoxComponent,
    InputEditorCkComponent,
    InputCompetitorComponent,
    InputCustomerEmailsComponent,
    InputLeadContactPhoneComponent,
    InputCustomerPhonesComponent,
    InputParticipantComponent,
    InputOpportunityServicesComponent,
    InputOpportunityCustomListValuesComponent,
    InputOpportunityCustomListRowComponent,
    InputHashtagComponent,
    InputMoneyComponent,
    InputSliderComponent,
    InputTimeComponent,
    InputCustomerContactComponent,
    SelectTimeComponent,
    SelectCountryComponent,
    SelectLanguageComponent,
    SelectHomepageComponent,
    SelectSectionComponent,
    SelectCityComponent,
    SelectCountyComponent,
    SelectNeighborhoodComponent,
    SelectAccountComponent,
    SelectContactComponent,
    SelectOpportunityTypeComponent,
    SelectOrganizationComponent,
    SelectSalutationComponent,
    SelectActivityPurposeComponent,
    SelectSalesRouteComponent,
    SelectClearanceComponent,
    SelectContactRoleGroupComponent,
    SelectOfferComponent,
    SelectOpportunityComponent,
    DateDiffPipe,
    SelectUserComponent,
    SelectSystemUserInfoComponent,
    SelectSalesCountyGroupComponent,
    FullSpinnerComponent,
    SelectSeaPortComponent,
    SelectAirPortComponent,
    SelectOfferUnitComponent,
    SelectCarryingFeatureComponent,
    SelectCarrierUnitComponent,
    SelectValueAddedServiceComponent,
    SelectAirLineComponent,
    InputOfferAirCargoComponent,
    SelectPaymentTermComponent,
    SelectSeaLineComponent,
    ActivityDocumentComponent,
    AssignmentDocumentComponent,
    SelectFuelCityComponent,
    SelectFuelCountyComponent,
    FormatFileSizePipe,
    OrderByPipe,
    InputActivityAssignmentComponent,
    SelectServiceUnitComponent,
    SelectServiceComponent,
    SelectServicePipe,
    SelectServiceUnitPipe,
    SpaceControlDirective,
    SelectSalesOrganizationComponent,
    SelectNotificationTemplateComponent,
    SelectOrganizationalStructureComponent,
    InputCustomerAddressComponent,
    NumbersOnlyDirective,
    NgModelChangeDebouncedDirective,
    NgModelValidationDirective,
    MultiSelectSystemUserComponent,
    MultiSelectUserComponent,
    MultiSelectCcUsersComponent,
    MultiSelectSalesRouteComponent,
    MultiSelectActivityStatusComponent,
    MultiSelectActivityTypeComponent,
    OpportunityCommunicationPanelComponent,
    AccountCommunicationPanelComponent,
    SelectTaxOfficeComponent,
    OpportunityStepperComponent,
    FinancialInquiryUploadComponent,
    LeadDraftInfoComponent,
    ConfirmRescheduleDialogComponent,
    ShipmentProfileIntervalPipe,
    SearchFilterPipe,
    MatHeaderFilterComponent,
    OpportunityDocumentComponent,
    DocumentUploadComponent,
    SelectActivityStatusComponent,
    SelectActivityTypeComponent,
    FocusOnShowDirective,
    SortByPipe,
    SortByHashtagOrderPipe,
    SelectCurrencyComponent,
    SelectAssignmentPriorityComponent,
    NetColorPickerComponent,
    SelectOpportunityStatusComponent,
    InputOpportunityServicesPipe,
    InputOpportunityServicesOneSelectPipe,
    ProfilePhotoComponent,
    SecureImagePipe,
    SelectMeetingStatusComponent,
    SelectOfferSearchableComponent,
    SelectOpportunitySearchableComponent,
    CurrencyReplacePipe,
    CustomNumberPipe,
    MatTableCustomizedFiltersComponent,
    MatTableColumnFilterComponent,
    MatTablePaginatorComponent,
    InputNumberComponent,
    UpperCaseDirective,
    InputDynamicFieldValuesComponent,
    InputDatetimeComponent,
    SelectActivityComponent,
    NormalizeDecimalSeparatorPipe,
    ReportCustomizedFiltersComponent,
    InputDynamicFieldPicklistComponent,
    MultiAccountDialogComponent,
    DocumentListComponent,
    SelectActivityPdfTemplateComponent,
    MatTablePaginatorComponent,
    InputUserMentionComponent,
    SelectBoardComponent,
    SelectBoardCardComponent,
    SelectBoardSectionComponent,
    InputAssignmentComponent,
    AssignmentFormComponent,
    BoardCardFormComponent,
    MailLinkListComponent,
    HtmlViewerComponent,
    MailAttachmentComponent,
    InputContactComponent,
    InputLeadDraftComponent,
    MailCircleAvatarComponent,
    InputUserMentionComponent,
    NetMultiSelectDocumentComponent,
    OpportunitySendingMailDialogComponent,
    SelectRouteLevelsComponent,
    SelectRoleComponent,
    SelectPermissionComponent,
    SelectCustomerExperienceChannelTypeComponent,
    SelectCustomerExperienceChannelComponent,
    MatTableHeaderComponent,
    SelectCustomerExperienceCategoriesComponent,
    SelectCustomerExperienceChartComponent,
    ScrollDirective,
    ChatMiddleComponent,
    ChatMessageComponent,
    TicketLinkListComponent,
    LeadDraftInfoActivitiesComponent,
    MatHeaderFilterClientComponent,
    DashboardThemeCardComponent,
    OpportunityApprovalsComponent,
    OpportunityApprovalRequestDialogComponent,
    SelectCoordinatorshipComponent,
    CustomListValuesComponent
  ],
  providers: [
    SecureImagePipe,
    NormalizeDecimalSeparatorPipe,
    NgxMaskPipe
  ]
})
export class SharedModule { }
