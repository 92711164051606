import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomerExperienceChatModel, CustomerExperienceTicketDetailChatHeaderModel } from '@core/api';
import icClose from '@iconify/icons-ic/twotone-close';
import icArrowDropUp from '@iconify/icons-ic/twotone-arrow-drop-up';
import icArrowDropDown from '@iconify/icons-ic/twotone-arrow-drop-down';
import { dropdownAnimation } from 'src/app/shared/animations/dropdown.animation';
import { fadeInUp400ms } from 'src/app/shared/animations/fade-in-up.animation';

type CXMailViewModel = { self: boolean, headerInfo: CustomerExperienceTicketDetailChatHeaderModel } & CustomerExperienceChatModel;

@Component({
  selector: 'net-customer-experience-mail-viewer',
  templateUrl: './customer-experience-mail-viewer.component.html',
  styleUrls: ['./customer-experience-mail-viewer.component.scss'],
  animations: [
    fadeInUp400ms,
    dropdownAnimation,
  ]
})
export class CustomerExperienceMailViewerComponent implements OnInit {

  icClose = icClose;
  icArrowDropUp = icArrowDropUp;
  icArrowDropDown = icArrowDropDown;

  contactDrawer = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CXMailViewModel
  ) { }

  ngOnInit(): void {
  }

  toggleContactDrawer() {
    this.contactDrawer = !this.contactDrawer;
  }

  formattedRecipients(email: string, cc: boolean = false): string {
    const cxChannelInfo = this.data?.ticketMessage?.cxChannel?.cxChannelInfo;

    if (cc) {
      return email.split(',').map(mail => mail.trim()).join(', ');
    }

    if (email) {
      const formattedMail = email.split(',').map(mail => mail.trim()).join(', ');
      return cxChannelInfo ? `${formattedMail}, ${cxChannelInfo}` : formattedMail;
    }
    return cxChannelInfo || '';
  }
}
