import { NotificationActions, NotificationActionType } from '@core/store';

export interface NotificationState {
  userId: string;
  assignmentCount: number;
  mailUnreadCount: number;
  notificationCount: number;
  leadAssignmentCount: number;
  isLoading: boolean;
  errorMessage: string;
  errorCode: string;
}

export const NotificationState: NotificationState = {
  userId: '',
  mailUnreadCount: 0,
  assignmentCount: 0,
  notificationCount: 0,
  leadAssignmentCount: 0,
  isLoading: false,
  errorMessage: null,
  errorCode: null,
};

export function notificationReducer(state = NotificationState, action: NotificationActions): NotificationState {
  switch (action.type) {
    case NotificationActionType.GET_NOTIFICATION:
      return {
        ...state,
        errorMessage: null,
        isLoading: true
      };
    case NotificationActionType.GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        assignmentCount: action.payload.assignmentCount,
        notificationCount: action.payload.notificationCount,
        userId: action.payload.userId,
        errorMessage: null,
        errorCode: null,
        isLoading: false,
      };
    case NotificationActionType.GET_NOTIFICATION_ERROR:
      return {
        ...state,
        isLoading: false,
        errorCode: action.payload.errorCode,
        errorMessage: action.payload.message
      };
    case NotificationActionType.INCREASE_NOTIFICATION_COUNT:
      return {
        ...state,
        notificationCount: state.notificationCount + 1
      };
    case NotificationActionType.DECREASE_NOTIFICATION_COUNT:
      return {
        ...state,
        notificationCount: state.notificationCount - 1
      };

    case NotificationActionType.RESET_NOTIFICATION_COUNT:
      return {
        ...state,
        notificationCount: 0
      };

    case NotificationActionType.UPDATE_NOTIFICATION_COUNT:
      return {
        ...state,
        notificationCount: action.count
      };
    case NotificationActionType.GET_LEAD_ASSIGNMENT_COUNT:
      return {
        ...state,
        errorMessage: null,
        isLoading: true
      };
    case NotificationActionType.GET_LEAD_ASSIGNMENT_COUNT_SUCCESS:
      return {
        ...state,
        leadAssignmentCount: action.payload,
        errorMessage: null,
        errorCode: null,
        isLoading: false,
      };
    case NotificationActionType.GET_LEAD_ASSIGNMENT_COUNT_ERROR:
      return {
        ...state,
        isLoading: false,
        errorCode: action.payload.errorCode,
        errorMessage: action.payload.message
      };
    case NotificationActionType.GET_MAIL_UNREAD_COUNT:
      return {
        ...state,
        errorMessage: null,
        isLoading: true
      };
    case NotificationActionType.GET_MAIL_UNREAD_COUNT_SUCCESS:
      return {
        ...state,
        mailUnreadCount: action.payload,
        errorMessage: null,
        errorCode: null,
        isLoading: false,
      };
    case NotificationActionType.GET_MAIL_UNREAD_COUNT_ERROR:
      return {
        ...state,
        isLoading: false,
        errorCode: action.payload.errorCode,
        errorMessage: action.payload.message
      };
    default:
      return state;
  }
}

export const assignmentCount = (state: NotificationState) => state.assignmentCount;
export const mailUnreadCount = (state: NotificationState) => state.mailUnreadCount;
export const notificationCount = (state: NotificationState) => state.notificationCount;
export const notificationErrorMessage = (state: NotificationState) => state.errorMessage;
export const notificationErrorCode = (state: NotificationState) => state.errorCode;
export const notificationIsLoading = (state: NotificationState) => state.isLoading;
