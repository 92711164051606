import { AfterViewInit, Component, ElementRef, HostBinding, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { fadeInUp400ms } from 'src/app/shared/animations/fade-in-up.animation';

import icClose from '@iconify/icons-ic/twotone-close';
import icOpenInNew from '@iconify/icons-ic/twotone-open-in-new';
import icFullscreen from '@iconify/icons-ic/twotone-fullscreen';
import icFullscreenExit from '@iconify/icons-ic/twotone-fullscreen-exit';
import { ApiService, CustomerExperienceChatModel, CustomerExperienceTicket, CustomerExperienceTicketService } from '@core/api';
import { CustomerExperienceTicketPreviewService } from './customer-experience-ticket-preview.service';
import { ScrollDirective } from 'src/app/shared/directives/scroll.directive';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'net-customer-experience-ticket-preview[preview][index][conversation]',
  templateUrl: './customer-experience-ticket-preview.component.html',
  styleUrls: ['./customer-experience-ticket-preview.component.scss'],
  animations: [fadeInUp400ms]
})
export class CustomerExperienceTicketPreviewComponent implements OnInit, AfterViewInit {

  @ViewChild(ScrollDirective) scrollDirective: ScrollDirective;

  private offsetX = 0;
  private offsetY = 0;
  private initialX = 0;
  private initialY = 0;

  private boxTop = 0;
  private boxLeft = 0;

  icClose = icClose;
  icOpenInNew = icOpenInNew;
  icFullscreen = icFullscreen;
  icFullscreenExit = icFullscreenExit;

  contactName: string;

  isLoading = true;
  isLoadingUp = true;
  pagination = {
    pageSize: 20,
  };

  allPrevMessagesLoaded = false;
  infiniteScrollDisabled = true;

  get element() {
    return this.elementRef.nativeElement;
  }

  @Input() index: number;
  @Input() conversation: CustomerExperienceChatModel[] = [];
  @Input() set preview(value: CustomerExperienceTicket) {
    if (value) {
      if (value.mergedTicket) {
        value = { ...value, code: value.mergedTicket.code, contactInfo: value.mergedTicket?.contactInfo, contactName: value.mergedTicket?.contactName };
      }
      this._preview = value;
      this.getChat();
    }
  }

  get preview(): CustomerExperienceTicket {
    return this._preview;
  }

  private _preview: CustomerExperienceTicket;

  @HostBinding('style.z-index') zindex = 100;
  @HostBinding('class.preview-expanded') expand = false;
  @HostBinding('class.preview-dragging') dragging = false;

  @HostListener('click') onClick() {
    this.zindex = this.expand ? 300 : 200;
  }

  @HostListener('dblclick') onDoubleClick() {
    this.toggleExpand();
  }

  @HostListener('document:click', ['$event']) onDocumentClick(event) {
    // When the user clicks outside the element, reset the z-index
    if (!this.expand && false === this.element.contains(event.target)) {
      this.zindex = 100 + this.index;
    }
  }

  @HostListener('window:mouseup', ['$event']) onWindowMouseUp() {
    if (!this.dragging) {
      return;
    }

    // Disable dragging
    this.dragging = false;
  }

  @HostListener('window:keydown', ['$event']) onWindowKeydown($event) {
    if (this.expand && $event.key === 'Escape') {
      this.toggleExpand();
    }
  }

  @HostListener('window:mousemove', ['$event']) onWindowMouseMove(event) {
    if (!this.dragging) {
      return;
    }

    // Calculate the distance moved by the cursor
    const distanceX = event.clientX - this.initialX;
    const distanceY = event.clientY - this.initialY;

    // Calculate the new position of the element based on the initial position and cursor movement
    const top = this.offsetY + distanceY;
    const left = this.offsetX + distanceX;

    // Prevent the element from moving outside the viewport
    if (top <= 0 || left <= 0) { return; }

    // Set the new position of the element
    this.element.style.top = `${top}px`;
    this.element.style.left = `${left}px`;

    // Remove initial positions
    this.element.style.removeProperty('right');
    this.element.style.removeProperty('bottom');
  }

  constructor(
    private router: Router,
    private elementRef: ElementRef<HTMLElement>,
    private customerExperienceTicketPreviewService: CustomerExperienceTicketPreviewService,
    private customerExperienceTicketService: CustomerExperienceTicketService,
    private apiService: ApiService
  ) { }


  ngAfterViewInit(): void {
  }

  getChat() {
    this.customerExperienceTicketService.getChat({
      ...this.pagination,
      filter: {
        ticketId: this.preview.ticketId
      }
    }).pipe(
      map(response => response.data),
      tap(() => this.getContacts())
    ).subscribe(response => {

      this.conversation = response.results.reverse();

      if (response.results.length < 20) {
        this.scrollUpPagination(true);
      } else {
        setTimeout(() => {
          this.scrollDirective.reset();
          this.infiniteScrollDisabled = false;
        }, 100);
      }
    });
  }

  getContacts() {
    this.apiService.phone.search({
      page: 1,
      pageSize: 10,
      filter: {
        phoneNumber: this.preview.contactInfo,
        getContact: true,
        getCustomer: false
      }
    }).pipe(
      map(response => response.data.results),
      untilDestroyed(this)
    ).subscribe(response => {

      const firstContact = response.find(x => x.contact && x.contact.customer)?.contact;

      if (firstContact) {
        this.contactName = `${firstContact.firstName} ${firstContact.lastName}`;
        this.isLoading = false;
        return;
      }

      this.contactName = this.preview.contactInfo;
      this.isLoading = false;
    });
  }

  onMouseDown(event) {
    // Store the initial click position
    this.initialX = event.clientX;
    this.initialY = event.clientY;

    // Store the initial element position
    this.offsetX = this.element.offsetLeft;
    this.offsetY = this.element.offsetTop;

    // Enable dragging
    this.dragging = true;

    // Increase the z-index when the user clicks on the element
    this.zindex = this.expand ? 300 : 200;
  }

  ngOnInit() {
    this.zindex = 100 + this.index;


    // Prepare stack item count by screen width
    let stackItem = 1;

    if (window.innerWidth > 1000) {
      stackItem = 2;
    }

    if (window.innerWidth > 1500) {
      stackItem = 3;
    }

    // Calculate initial position to stack previews
    const row = Math.floor(this.index / stackItem);
    const column = this.index % stackItem;

    const width = this.element.offsetWidth;
    const height = this.element.offsetHeight;

    const right = column * width + (column * 18) + (row * 18) + 24 + 56;
    const bottom = row * (row === 0 ? height : height * 0.05) + (row * 18) + 24;

    this.element.style.right = `${right}px`;
    this.element.style.bottom = `${bottom}px`;
  }

  open() {
    this.close();

    window.open(
      this.router.serializeUrl(this.router.createUrlTree(['/customer-experience', 'ticket', this.preview.mergedTicketId ?? this.preview.ticketId])),
      '_blank'
    );
  }

  close() {
    this.customerExperienceTicketPreviewService.remove(this.preview);
  }

  toggleExpand() {
    // Make expanded
    if (false === this.expand) {
      this.expand = true;
      this.zindex = 300;

      this.boxTop = this.element.offsetTop;
      this.boxLeft = this.element.offsetLeft;

      this.element.style.removeProperty('top');
      this.element.style.removeProperty('left');

      return;
    }

    // Return to box view
    this.expand = false;
    this.zindex = 100 + this.index;

    this.element.style.top = `${this.boxTop}px`;
    this.element.style.left = `${this.boxLeft}px`;
  }

  onScrollUp() {
    this.scrollDirective.prepareFor('up');
    this.scrollUpPagination();
  }

  private scrollUpPagination(firstScrollUp?: boolean) {

    if (this.allPrevMessagesLoaded) {
      return;
    }

    this.isLoadingUp = true;
    this.customerExperienceTicketService.getChat({
      ...this.pagination,
      filter: {
        ticketId: this.preview.ticketId,
        ticketChatHistoryId: this.conversation[0].ticketChatHistoryId
      }
    }).pipe(map(response => response.data)).subscribe(response => {
      if (response.results.length === 0) {
        this.allPrevMessagesLoaded = true;
      }
      this.conversation = [...response.results.reverse(), ...this.conversation];
      this.isLoadingUp = false;

      if (firstScrollUp) {
        setTimeout(() => this.scrollDirective.reset()); // method to restore the scroll position
        this.infiniteScrollDisabled = false;
      }
    });
  }

}
