import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component, DoCheck, ElementRef, EventEmitter, HostBinding, Input,
  OnDestroy, OnInit, Optional, Output, Self, ViewChild
} from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { replaceTurkishCharacters } from '../../utils/replace-turkish-characters';
import { MatSelect } from '@angular/material/select';
import { ChannelType, CustomerExperienceChannel, CustomerExperienceChannelSettingsService } from '@core/api';
import { SelectCustomerExperienceChannelItemsPipe } from '../../pipes/select-customer-experience-channel-items.pipe';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'net-select-customer-experience-channel',
  templateUrl: './select-customer-experience-channel.component.html',
  styleUrls: ['./select-customer-experience-channel.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectCustomerExperienceChannelComponent,
    },
    SelectCustomerExperienceChannelItemsPipe
  ],
})
export class SelectCustomerExperienceChannelComponent implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<string | string[] | null> {
  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'select-channel';
  describedBy = '';
  stateChanges = new Subject<void>();

  channels: CustomerExperienceChannel[] = [];
  selectCtrl = new UntypedFormControl();
  searchText = '';

  @Input() multiple: boolean;
  @Input() tabIndex;
  @Input() mode: 'add' | 'edit';

  @Input() set selectedChannelTypes(value: string[]) {
    if (!value || value.length === 0) {
      return;
    }
    this.initDefaultOptions(value);
  }

  @Input() set directRequest(value: boolean) {
    if (value) {
      this.initDefaultOptions();
    }
    this._directRequest = value;
  }

  get directRequest(): boolean {
    return this._directRequest;
  }

  private _directRequest = false;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.selectCtrl.disable();
    } else {
      this.selectCtrl.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): string | string[] | null {
    return this.selectCtrl.value as string | string[];
  }
  set value(value: string | string[] | null) {
    this.selectCtrl.setValue(value);
    this.onChange(value);
    this.stateChanges.next();
  }
  private _value: CustomerExperienceChannel | CustomerExperienceChannel[];

  @Output() dropdownValues: EventEmitter<CustomerExperienceChannel[]> = new EventEmitter<CustomerExperienceChannel[]>();

  @Output() isExistDisableValue: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('channelSelect') channelSelect: MatSelect;

  @HostBinding('id') id = `select-channel-${SelectCustomerExperienceChannelComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() { return this.focused || !this.empty; }

  get empty() { return !this.value; }

  get filteredChannels(): CustomerExperienceChannel[] {
    return this._filteredChannels ?? [];
  }

  set filteredChannels(channels: CustomerExperienceChannel[]) {
    this._filteredChannels = channels;
  }

  private _filteredChannels: CustomerExperienceChannel[];

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private channelService: CustomerExperienceChannelSettingsService,
    private selectCustomerExperienceChannelItemsPipe: SelectCustomerExperienceChannelItemsPipe,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.selectCtrl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.isExistDisableValueCheck();
    });
  }

  private initDefaultOptions(cxChannelTypeIds?: string[]): Promise<void> {
    return new Promise<void>((resolve) => {
      this.channelService.search({
        filter: {
          ...this.mode === 'add' ? { activeFlag: true } : {},
          cxChannelTypeIds
        },
        orderBy: 'cxChannelName',
        orderType: 'ASC',
        page: 1,
        pageSize: 1000
      })
        .toPromise()
        .then((response) => {
          this.channels = response.data.results;
          this.filteredChannels = this.channels;

          if (!this.value && !this.directRequest && this.filteredChannels.length === 1) {

            this.value = this.filteredChannels.map(v => v.cxChannelId);
          }

          this.isExistDisableValueCheck(); // Check if there is any disabled value in the list

          this.dropdownValues.emit(this.channels);

        })
        .finally(() => resolve());
    });
  }

  ngOnInit(): void {
    // this.initDefaultOptions();
  }

  selectAll() {

    if (!this.multiple) {
      return;
    }

    this.selectCtrl.patchValue(this.filteredChannels);
    this.value = this.filteredChannels.map(c => c.cxChannelId);

  }

  deselectAll() {

    if (!this.multiple) {
      return;
    }

    this.selectCtrl.patchValue(null);
    this.value = [];
  }

  onKey(event) {
    if (!this.multiple) {
      return;
    }
    const selectedChannels = Array.isArray(this.value) ? this.getFilteredList().filter(channelType =>
      (this.value as CustomerExperienceChannel[]).map(type => type.cxChannelId).includes(channelType.cxChannelTypeId)) : [];
    this.filteredChannels = [...new Set([...this.search(this.getFilteredList(), 'name', event.target.value), ...selectedChannels])];

  }

  search(searchList: any[], searchKey: string, searchText: string) {
    const filter = replaceTurkishCharacters(searchText).toUpperCase();
    return searchList?.filter(option => option[searchKey].toUpperCase().includes(filter));
  }

  openedChange(isOpened: boolean) {
    if (isOpened) {
      const panel = this.channelSelect.panel.nativeElement;
      panel.scrollTop = 0;
    }
  }

  onSelectionChange() {
    this.onChange(this.value);
    this.stateChanges.next();
  }

  getFilteredList() {
    return this.selectCustomerExperienceChannelItemsPipe.transform(this.filteredChannels, this.selectCtrl, this.mode, this.multiple);
  }

  getChannelById(channelId: string) {
    return this.channels.find(channel => channel.cxChannelId === channelId);
  }

  isLastItem(channelId: string): boolean {
    const selectedItems = this.selectCtrl.value;
    const lastItem = selectedItems[selectedItems.length - 1];
    return lastItem === channelId;
  }

  isExistDisableValueCheck() {

    const disableItems = this.filteredChannels.filter(channel => !channel.activeFlag);

    this.isExistDisableValue.emit(
      disableItems.filter(channel => {
        if (this.multiple && this.selectCtrl.value?.includes(channel.cxChannelId)) {
          return true;
        } else if (!this.multiple && this.selectCtrl.value === channel.cxChannelId) {
          return true;
        } else {
          return false;
        }
      }).length > 0
    );
  }

  onChange = (_: any) => { };
  onTouched = () => { };
  compareWith = (one: string, two: string) =>
    one && two && one === two
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');

  writeValue(value: string | string[]) {
    this.value = value;
  }

  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

}
