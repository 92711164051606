import { UntypedFormGroup } from '@angular/forms';
import { DynamicField, DynamicFieldValueTypeAsString } from '@core/api';
import {
  DynamicFieldConditionFieldSource,
  DynamicFieldConditionTypeAsString,
} from '@core/api/dynamic-field-condition/dynamic-field-condition.enum';
import moment from 'moment';

export function resolveDynamicFieldConditions(
  field: DynamicField,
  form: UntypedFormGroup,
  fields: DynamicField[],
  accountStatusId,
  activityStatusId,
  assignmentStatusId,
  opportunityStatusId,
  opportunityTypeId,
  organizationId,
  salesOrganizationId,
  serviceIds
) {
  if (field.dynamicFieldConditions.length === 0) {
    return true;
  }
  const resolveCondition = field.dynamicFieldConditions.map(
    (dynamicFieldCondition) => {
      const conditionType = dynamicFieldCondition.conditionType;
      if (dynamicFieldCondition.dynamicFieldConditionProperty?.fieldSource) {
        return getConditionStaticField(
          dynamicFieldCondition.dynamicFieldConditionProperty?.fieldSource,
          conditionType,
          dynamicFieldCondition.value?.split(', '),
          accountStatusId,
          activityStatusId,
          assignmentStatusId,
          opportunityStatusId,
          opportunityTypeId,
          organizationId,
          salesOrganizationId,
          serviceIds
        );
      }
      const formValue = form.get(
        dynamicFieldCondition.dynamicFieldConditionProperty?.dynamicFieldId
      )?.value;
      const selectedField = fields.find(
        (f) =>
          f.dynamicFieldId ===
          dynamicFieldCondition?.dynamicFieldConditionProperty?.dynamicFieldId
      );
      const dynamicFieldValueType = selectedField.dynamicFieldType.valueType;

      switch (dynamicFieldValueType) {
        case DynamicFieldValueTypeAsString.DATE:
        case DynamicFieldValueTypeAsString.DATETIME:
          return getVisibilityDynamicField(
            conditionType,
            formValue ? moment(formValue).utc(true).format('YYYY-MM-DD') : null,
            getDateVisibility(dynamicFieldCondition.value)
          );
        case DynamicFieldValueTypeAsString.DECIMAL:
        case DynamicFieldValueTypeAsString.INTEGER:
          return getVisibilityDynamicField(
            conditionType,
            formValue,
            dynamicFieldCondition.value
          );
        case DynamicFieldValueTypeAsString.PICKLIST:
          if (selectedField.multipleValueFlag) {
            let formValueArray = [];
            if (formValue instanceof Array) {
              formValueArray = formValue;
            } else {
              formValueArray.push(formValue);
            }
            return getPicklistVisibility(
              conditionType,
              formValueArray ?? [],
              dynamicFieldCondition?.value?.split(', ')
            );
          } else {
            const formValueArray = formValue ? [formValue] : [];
            return getPicklistVisibility(
              conditionType,
              formValueArray ?? [],
              dynamicFieldCondition?.value?.split(', ')
            );
          }
        case DynamicFieldValueTypeAsString.TEXT:
          return getVisibilityDynamicField(
            conditionType,
            formValue,
            dynamicFieldCondition.value
          );
      }
    }
  );

  return resolveCondition.every((x) => x);
}

export function getDateVisibility(value) {
  switch (value) {
    case 'Today':
      return moment().format('YYYY-MM-DD');
  }
}

export function getVisibilityDynamicField(
  conditionType,
  formValue,
  conditionDynamicFieldValue?
) {
  switch (conditionType) {
    case DynamicFieldConditionTypeAsString.Contains:
      return formValue?.includes(conditionDynamicFieldValue);
    case DynamicFieldConditionTypeAsString.LessThan:
      return formValue < conditionDynamicFieldValue;
    case DynamicFieldConditionTypeAsString.GreaterThan:
      return formValue > conditionDynamicFieldValue;
    case DynamicFieldConditionTypeAsString.Equals:
      return formValue === conditionDynamicFieldValue;
    case DynamicFieldConditionTypeAsString.NotEquals:
      return formValue !== conditionDynamicFieldValue;
    case DynamicFieldConditionTypeAsString.Null:
      return !formValue;
    case DynamicFieldConditionTypeAsString.NotNull:
      return !!formValue;
  }
}

export function getPicklistVisibility(
  conditionType,
  formValue: any,
  conditionDynamicFieldValue: any
) {
  switch (conditionType) {
    case DynamicFieldConditionTypeAsString.Contains:
      return formValue.some((x) => conditionDynamicFieldValue.includes(x));
    case DynamicFieldConditionTypeAsString.Equals:
      return JSON.stringify(formValue) === JSON.stringify(conditionDynamicFieldValue);
    case DynamicFieldConditionTypeAsString.NotEquals:
      return (formValue instanceof Array) ? !(conditionDynamicFieldValue?.filter((x) => formValue.includes(x)).length > 0) :
        !formValue.includes(conditionDynamicFieldValue);
    case DynamicFieldConditionTypeAsString.Null:
      return formValue?.length === 0;
    case DynamicFieldConditionTypeAsString.NotNull:
      return formValue?.length > 0;
  }
}

export function getConditionStaticField(
  fieldSource,
  conditionType,
  conditionDynamicFieldValue,
  accountStatusId,
  activityStatusId,
  assignmentStatusId,
  opportunityStatusId,
  opportunityTypeId,
  organizationId,
  salesOrganizationId,
  serviceIds
) {
  switch (fieldSource) {
    case DynamicFieldConditionFieldSource.AccountStatus:
      return getConditionStaticFieldVisibility(
        conditionType,
        accountStatusId,
        conditionDynamicFieldValue
      );
    case DynamicFieldConditionFieldSource.ActivityStatus:
      return this.getConditionStaticFieldVisibility(
        conditionType,
        activityStatusId,
        conditionDynamicFieldValue
      );
    case DynamicFieldConditionFieldSource.AssignmentStatus:
      return getConditionStaticFieldVisibility(
        conditionType,
        assignmentStatusId,
        conditionDynamicFieldValue
      );
    case DynamicFieldConditionFieldSource.OpportunityStatus:
      return getConditionStaticFieldVisibility(
        conditionType,
        opportunityStatusId,
        conditionDynamicFieldValue
      );
    case DynamicFieldConditionFieldSource.OpportunityType:
      return getConditionStaticFieldVisibility(
        conditionType,
        opportunityTypeId,
        conditionDynamicFieldValue
      );
    case DynamicFieldConditionFieldSource.Organization:
      return getConditionStaticFieldVisibility(
        conditionType,
        organizationId,
        conditionDynamicFieldValue
      );
    case DynamicFieldConditionFieldSource.SalesOrganization:
      return getConditionStaticFieldVisibility(
        conditionType,
        salesOrganizationId,
        conditionDynamicFieldValue
      );
    case DynamicFieldConditionFieldSource.ServiceMainService:
      return getServiceVisibility(
        conditionType,
        conditionDynamicFieldValue,
        serviceIds
      );
  }
}

export function getConditionStaticFieldVisibility(
  conditionType,
  formValue: string,
  conditionDynamicFieldValue: string[]
) {
  switch (conditionType) {
    case DynamicFieldConditionTypeAsString.Contains:
      return !!conditionDynamicFieldValue?.includes(formValue);
    case DynamicFieldConditionTypeAsString.Equals:
      return conditionDynamicFieldValue?.some((x) => x === formValue);
    case DynamicFieldConditionTypeAsString.NotEquals:
      return !conditionDynamicFieldValue?.some((x) => x === formValue);
    case DynamicFieldConditionTypeAsString.Null:
      return !formValue;
    case DynamicFieldConditionTypeAsString.NotNull:
      return !!formValue;
  }
}

export function getServiceVisibility(
  conditionType,
  conditionDynamicFieldValue,
  serviceIds
) {
  switch (conditionType) {
    case DynamicFieldConditionTypeAsString.Contains:
      return (
        serviceIds.filter((x) => conditionDynamicFieldValue.includes(x))
          .length > 0
      );
    case DynamicFieldConditionTypeAsString.Equals:
      return serviceIds?.some((x) => conditionDynamicFieldValue.includes(x));
    case DynamicFieldConditionTypeAsString.NotEquals:
      return !serviceIds?.some((x) => conditionDynamicFieldValue.includes(x));
    case DynamicFieldConditionTypeAsString.Null:
      return serviceIds.length === 0;
    case DynamicFieldConditionTypeAsString.NotNull:
      return serviceIds.length > 0;
  }
}
