<form [class.dialog]="isDialog" [class.page]="isPage" [formGroup]="form" class="form-wrapper">
  <ng-template #content>
    <div fxLayout="column">
      <ng-container *ngIf="activity !== undefined">
        <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="24px">
          <mat-form-field fxFlex>
            <mat-label>{{'ACTIVITY.CUSTOMER' | translate}}</mat-label>
            <input matInput *ngIf="type === activityDialogTypeEnum.LEAD_DRAFT; else selectAccount"
              [value]="activityCustomerName" [disabled]="true">

            <ng-template #selectAccount>
              <net-select-account class="customer-name-zone" formControlName="account"
                (selectionChange)="clearParticipants($event)"></net-select-account>
            </ng-template>
          </mat-form-field>
        </div>

        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="24px">
          <mat-form-field fxFlex fxFlex.gt-xs="33">
            <mat-label>{{'ACTIVITY.ACTIVITY_TYPE' | translate}}</mat-label>
            <net-select-activity-type [isDisabled]="isDisabled" formControlName="type"></net-select-activity-type>
          </mat-form-field>

          <mat-form-field fxFlex fxFlex.gt-xs="33">
            <mat-label>{{'ACTIVITY.ACTIVITY_STATUS' | translate}}</mat-label>
            <net-select-activity-status [isCreate]="isCreateVideoActivity" [isDisabled]="isDisabled"
              formControlName="status">
            </net-select-activity-status>
          </mat-form-field>

          <mat-form-field
            *ngIf="form.get('type').value === ActivityTypeList.VIDEOCONF && activityVideoConferenceTool.length > 0"
            fxFlex fxFlex.gt-xs="33">
            <mat-label>{{'ACTIVITY.VIDEO_CALL_TOOL' | translate}}</mat-label>
            <net-select-activity-video-conference-tool [activityVideoConferenceTool]="activityVideoConferenceTool"
              [isDisabled]="isDisabled" formControlName="meetingTypeId">
            </net-select-activity-video-conference-tool>
          </mat-form-field>

          <div *ngIf="form.get('type').value !== ActivityTypeList.VIDEOCONF || activityVideoConferenceTool.length === 0"
            fxFlex fxFlex.gt-xs="33"></div>

        </div>

        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="24px">
          <mat-form-field fxFlex fxFlex.gt-xs="33">
            <mat-label>{{"GENERAL.DATE" | translate}}</mat-label>
            <input (click)="datePicker.open()" [disabled]="isDisabled" [matDatepicker]="datePicker" [max]="dateMax"
              [min]="dateMin" formControlName="date" matInput readonly>
            <mat-datepicker #datePicker></mat-datepicker>
            <mat-datepicker-toggle [for]="datePicker" matSuffix></mat-datepicker-toggle>
          </mat-form-field>

          <mat-form-field fxFlex fxFlex.gt-xs="33">
            <mat-label>{{"GENERAL.START_TIME" | translate}}</mat-label>
            <net-input-time [isDisabled]="isDisabled" formControlName="timeStart"></net-input-time>
          </mat-form-field>

          <mat-form-field fxFlex fxFlex.gt-xs="33">
            <mat-label>{{"GENERAL.END_TIME" | translate}}</mat-label>
            <net-input-time [isDisabled]="isDisabled" [min]="form.get('timeStart').value"
              formControlName="timeEnd"></net-input-time>
          </mat-form-field>
        </div>

        <mat-form-field>
          <mat-label>{{"GENERAL.ADDRESS" | translate}}</mat-label>
          <net-input-customer-address [checkPermissions]="false"
            [customer]="leadDraftInfo?.leadDraft.customer ?? form.get('account').value?.customer"
            [isDisabled]="isDisabled" [reSelectFirst]="reSelectAddress" formControlName="address">
          </net-input-customer-address>
        </mat-form-field>

        <mat-form-field class="mb-4">
          <mat-label>{{"ACTIVITY.PARTICIPANTS" | translate}}</mat-label>
          <net-input-participant [permissionControl]="false"
            [customer]="leadDraftInfo?.leadDraft.customer ?? form.get('account').value?.customer ?? leadDraftInfoActivityCustomer"
            [isDisabled]="isDisabled" [removeSelf]="false"
            [salesOrganizationId]="form.get('account').value?.salesOrganizationId"
            (participantSelection)="onParticipantSelectionChange($event)"
            formControlName="participants"></net-input-participant>
          <mat-hint>
            {{"ACTIVITY.PLEASE_SEARCH_TO_ADD_PEOPLE_IN_YOUR_ORGANIZATION" | translate }}
          </mat-hint>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{"ACTIVITY.PURPOSE" | translate}}</mat-label>
          <net-select-activity-purpose [type]="type" [account]="leadDraftInfo?.leadDraft ?? form.get('account').value"
            [isDisabled]="isDisabled" formControlName="purpose">
          </net-select-activity-purpose>
          <mat-error *ngIf="form.get('purpose').hasError('invalidPurpose')">
            {{"ACTIVITY.ACTIVITY_PURPOSE_IS_PASSIVE_CANNOT_BE_UPDATED" | translate }}
          </mat-error>
        </mat-form-field>

        <net-input-dynamic-field-values [entityId]="DynamicFieldEntityId.ACTIVITY"
          [entityName]="DynamicFieldEntityAsString.ACTIVITY" [activityStatusId]="form.get('status').value"
          [isDisabled]="isDisabled" [openSeparator]="openSeparator" [mode]="!activity ? 'add' : 'edit'" columns="3"
          formControlName="dynamicFieldValues"></net-input-dynamic-field-values>

        <mat-form-field>
          <mat-label>{{"ACTIVITY.MEETING_NOTE" | translate}}</mat-label>
          <net-input-editor-ck (editorLength)="changeEditorLength($event)" [isDisabled]="isDisabled"
            [limit]="{active: true, textLimit: 5000}" [type]="'Activity'"
            formControlName="description"></net-input-editor-ck>
          <a (click)="joinMeeting()" *ngIf="canSeeLink()" class="underline text-primary-600 hover:text-primary-800"
            href="javascript:void(0)">{{"ACTIVITY.CLICK_TO_JOIN__THE_MEETING" | translate}}</a>
          <mat-hint [ngClass]="{'text-red-800': description.errors}" align="end">{{editorLength}} / 5000</mat-hint>
        </mat-form-field>

        <ng-container *ngIf="hasAssignmentPermission && (form.get('account').value || leadDraftInfo)">
          <div class="assignment">
            <div class="control-full separator">
              <div fxLayout="column" class="separator-header">
                <div fxLayout="row" fxLayoutGap="16px" class="py-2 rounded-t bar cursor-pointer"
                  [ngClass]="{'rounded-b': !isActivityAssignmentPanelOpened}"
                  (click)="isActivityAssignmentPanelOpened = !isActivityAssignmentPanelOpened">
                  <div fxFlex="100" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px" fxLayoutGap.xs="8px">

                    <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                      <div class="rounded-full separator-icon flex items-center justify-center bg-yellow">
                        <mat-icon class="opacity-50 text-white" size="18px" [icIcon]="icAssignment"
                          color="#fff"></mat-icon>
                      </div>
                      <h2 class="separator-title">{{"ACTIVITY.ACTIVITY_ASSIGNMENTS" | translate}}</h2>
                    </div>
                    <div fxFlex></div>

                    <button fxFlex="5" type="button" mat-icon-button matSuffix>
                      <mat-icon [icIcon]="isActivityAssignmentPanelOpened ? icKeyboardArrowUp : icKeyboardArrowDown"
                        size="20px"></mat-icon>
                    </button>
                  </div>
                </div>
              </div>

              <div *ngIf="isActivityAssignmentPanelOpened">
                <div class="form-fields control-full separator-content" fxLayout="row" fxFlex="100">
                  <net-input-activity-assignment [leadDraftInfo]="leadDraftInfo" [account]="form.get('account').value"
                    [isDisabled]="isDisabled" formControlName="activityAssignment"></net-input-activity-assignment>
                </div>
              </div>
            </div>
          </div>

          <mat-form-field class="activity-note" *ngIf="hasSendMailWithActivity">
            <mat-label>{{"ACTIVITY.INTERNAL_PRIVATE_NOTES" | translate}}</mat-label>
            <textarea [readonly]="isDisabled" formControlName="privateNote" matInput></textarea>
          </mat-form-field>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="activity === undefined">
        <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="24px">
          <ngx-skeleton-loader appearance="line" fxFlex></ngx-skeleton-loader>
          <ngx-skeleton-loader appearance="line" fxFlex></ngx-skeleton-loader>
        </div>
        <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="24px">
          <ngx-skeleton-loader appearance="line" fxFlex></ngx-skeleton-loader>
          <ngx-skeleton-loader appearance="line" fxFlex></ngx-skeleton-loader>
          <ngx-skeleton-loader appearance="line" fxFlex fxFlex.gt-xs="130px"></ngx-skeleton-loader>
          <ngx-skeleton-loader appearance="line" fxFlex fxFlex.gt-xs="100px"></ngx-skeleton-loader>
          <ngx-skeleton-loader appearance="line" fxFlex fxFlex.gt-xs="100px"></ngx-skeleton-loader>
        </div>
        <ngx-skeleton-loader appearance="line" fxFlex></ngx-skeleton-loader>
        <ngx-skeleton-loader appearance="line" fxFlex></ngx-skeleton-loader>
        <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="24px">
          <ngx-skeleton-loader appearance="line" style="width: 150px"></ngx-skeleton-loader>
          <ngx-skeleton-loader appearance="line" fxFlex></ngx-skeleton-loader>
        </div>
        <ngx-skeleton-loader appearance="line" class="textarea" fxFlex></ngx-skeleton-loader>
      </ng-container>
    </div>
  </ng-template>

  <ng-template #action>
    <div class="w-full mt-2" fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="start center"
      fxLayoutGap="24px">
      <div class="flex flex-col gap-1" fxFlex="grow">
        <p class="text-hint text-xs">{{"GENERAL.FIELDS_MARKED_WITH_*_ARE_REQUIRED" | translate}}</p>
        <ng-container *ngIf="getSendSurveyMailCondition()">
          <mat-checkbox formControlName="sendSurveyMail" color="primary" [disabled]="!hasNPSSendingUpdatePermission">
            {{"SURVEY.SEND_NPS_SURVEY_WHEN_ACTIVITY_SAVED" | translate}}
          </mat-checkbox>
        </ng-container>
      </div>

      <button (click)="joinMeeting()" *ngIf="canJoinMeeting()" class="ml-auto join-meeting-button" fxFlex.gt-xs="140px"
        fxFlex.xs="100%" mat-raised-button type="button">
        <span fxHide.lt-sm>{{"ACTIVITY.JOIN_THE_MEETING" | translate}}</span>
      </button>
      <button (click)="watchMeeting()" *ngIf="canWatchMeeting()" class="ml-auto join-meeting-button"
        fxFlex.gt-xs="140px" fxFlex.xs="100%" mat-raised-button type="button">
        <span fxHide.lt-sm>{{"ACTIVITY.WATCH_THE_MEETING" | translate}}</span>
      </button>


      <div *ngIf="isDisabled" class="w-full">
        <p class="alert alert-icon alert-warning">
          <mat-icon [icIcon]="icWarning"></mat-icon>
          {{ 'ACTIVITY.ACTIVITY_DETAIL_CAN_ONLY_BE_EDIT_BY_THE_CREATOR_USER' | translate}}
        </p>
      </div>

      <net-progress-button (btnClick)="activityAction = 'save_send'; onSubmit()"
        *ngIf="!hideSaveAndSendButton &&
              form.get('status').value === activityStatus.COMPLETED && hasSendMailWithActivity &&
              (!activity || hasActivityUpdateAllUserPermission ||  (activity && activity['createdBy'] === user.userId))" [active]="loading"
        [disabled]="isPage && type === activityDialogTypeEnum.LEAD_DRAFT && !hasLeadDraftActivityUpdatePermission || !hasActivityUpdatePermission"
        [icon]="icSave" [text]="'GENERAL.SAVE_SEND' | translate" class="ml-auto">
      </net-progress-button>

      <net-progress-button (btnClick)="activityAction = 'save'; onSubmit()"
        *ngIf="activity !== undefined && !isDisabled" [active]="loading"
        [disabled]="isPage && type === activityDialogTypeEnum.LEAD_DRAFT && !hasLeadDraftActivityUpdatePermission || !hasActivityUpdatePermission"
        [icon]="icSave" [text]="'GENERAL.SAVE' | translate" class="ml-auto">
      </net-progress-button>
    </div>

  </ng-template>

  <ng-template [ngIf]="isDialog">
    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
      <h2 class="m-0" fxFlex="auto">
        <span *ngIf="activity">{{"ACTIVITY.ACTIVITY_DETAIL" | translate}}</span>
        <span *ngIf="!activity">{{"ACTIVITY.NEW_ACTIVITY" | translate}}</span>
      </h2>

      <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
        <mat-icon [icIcon]="icClose"></mat-icon>
      </button>
    </div>

    <mat-divider class="-mx-6 mb-2 text-border"></mat-divider>
    <mat-dialog-content>
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </mat-dialog-content>
    <mat-dialog-actions>
      <ng-container [ngTemplateOutlet]="action"></ng-container>
    </mat-dialog-actions>
  </ng-template>
  <ng-template [ngIf]="isPage">
    <ng-container [ngTemplateOutlet]="content"></ng-container>
    <div class="w-full" fxLayout="row" fxLayoutAlign="start center">
      <ng-container [ngTemplateOutlet]="action"></ng-container>
    </div>
  </ng-template>
</form>