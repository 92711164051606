import { NotificationState } from '@core/store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectNotificationsState = createFeatureSelector('notification');

export const getNotificationCount = createSelector(
  selectNotificationsState,
  (state: NotificationState) => state.notificationCount
);

export const getAssignmentCount = createSelector(
  selectNotificationsState,
  (state: NotificationState) => state.assignmentCount
);

export const getLeadAssignmentCount = createSelector(
  selectNotificationsState,
  (state: NotificationState) => state.leadAssignmentCount
);

export const getMailUnreadCount = createSelector(
  selectNotificationsState,
  (state: NotificationState) => state.mailUnreadCount
);
