export enum DynamicFieldTypeId {
  URL = '41371bb0-82c7-43b0-8480-8f6804269c7a',
  DATE = 'bcfc924d-6e64-4aa8-b4c0-07f28d3ea922',
  PHONE = '1fa4379c-c050-4d4b-b7c9-51c30f31751c',
  EMAIL = '04e8d9ed-ac34-46bf-800d-3f7171487eaa',
  NUMBER = 'b86b64c6-368e-4d71-87ca-2244822e0a34',
  DECIMAL = '8615156a-fdc2-44e8-abde-80a999b011fe',
  CURRENCY = '840bdfd4-e63c-4f6f-94c4-699a3f5d1b03',
  DATETIME = '1c4a9d43-6e96-4599-94f3-5474f10c1d09',
  PICKLIST = '485a6430-5fb2-4a14-b140-8744d4f92fb1',
  RICH_TEXT = '7780e7da-53da-4091-9b07-adcf781ed927',
  PERCENTAGE = 'b9c6d5c7-56c0-4f79-a7ca-380b2061e669',
  MULTI_LINE_TEXT = '01d04b43-e43a-4c93-ac30-2d23e097c577',
  SINGLE_LINE_TEXT = '4bc8c646-8d72-4827-aee1-511a616d6994',
  SEPARATOR = '18c932cd-ab59-4b6b-bedd-77d8f8e5bf8c'
}

export enum DynamicFieldDefaultValueId {
  DATE_NONE = '2b492498-ef9a-4c46-825d-e96f244d79d5',
  DATE_TODAY = 'da1ec770-e8b5-4def-8889-62bb8dd8a4e9',
  DATE_TOMORROW = '2e135e53-9be8-4715-90cc-f6263508f319',
  DATE_NEXT_DAY = '3f4bfc00-b6f1-4993-9747-659dd25d2ee5',
  DATE_YESTERDAY = 'b89ba736-1aca-4e39-b0b0-eaf22921e5ca',
  DATETIME_NOW = '3f9de155-ecef-4943-9cd5-1a7450ed7a0d',
  DATETIME_NONE = 'e50df6d4-44b1-4d74-8541-f74eb71c4630',
}

export enum DynamicFieldEntityId {
  TASK = '43862a69-a5d8-425a-9acc-3acf6f801933',
  ADDRESS = '7dac891f-9981-4afc-b81f-9f8b688131ce',
  CONTACT = 'e31f32c1-05d8-4389-a8fe-4fa8a6456f05',
  ACTIVITY = '417df51d-b7dd-40f6-8ff4-224ec18a1a7b',
  CUSTOMER = '12429e00-d92d-41c2-9a61-939f9b577de7',
  LEAD_DRAFT = 'ec558f26-9747-4095-836a-1dd6e0941f23',
  OPPORTUNITY = '22129d3c-794a-40f4-8365-92594462481d',
  BOARD = 'd827efdf-a133-4283-95b2-2e1c1f94b63a',
  CUSTOM_LIST = 'aee1f348-ba78-4ef6-80a7-428c1c369227'
}

export enum DynamicFieldEntityAsString {
  TASK = 'Assignment',
  ADDRESS = 'Address',
  CONTACT = 'Contact',
  ACTIVITY = 'Activity',
  CUSTOMER = 'Customer',
  LEAD_DRAFT = 'Lead',
  OPPORTUNITY = 'Opportunity',
  BOARD = 'Board'
}

export enum DynamicFieldValueType {
  DATE = 3,
  TEXT = 0,
  INTEGER = 1,
  DECIMAL = 2,
  DATETIME = 4,
  PICKLIST = 5,
  SEPARATOR = 6
}

export enum DynamicFieldValueTypeAsString {
  DATE = 'Date',
  TEXT = 'Text',
  INTEGER = 'Integer',
  DECIMAL = 'Decimal',
  DATETIME = 'Datetime',
  PICKLIST = 'Picklist',
  SEPARATOR = 'Separator'
}
