import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  FormGroupDirective,
  NgControl,
  ValidationErrors,
} from '@angular/forms';

import { debounceTime, map, startWith } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';
import { MatFormFieldControl } from '@angular/material/form-field';

import icLink from '@iconify/icons-ic/twotone-link';
import icClear from '@iconify/icons-ic/twotone-clear';
import icKeyboardArrowDown from '@iconify/icons-ic/keyboard-arrow-down';
import icKeyboardArrowUp from '@iconify/icons-ic/keyboard-arrow-up';
import icList from '@iconify/icons-fa-solid/list-alt';
import icCircle from '@iconify/icons-material-symbols/circle';

import {
  DynamicField,
  DynamicFieldEntityId,
  DynamicFieldPicklist,
  DynamicFieldService,
  DynamicFieldTypeId,
  DynamicFieldValue
} from '@core/api';
import {
  getDynamicFieldControllerValue,
  getDynamicFieldValidations,
  getDynamicFieldValueFromInputByField,
  isDecimalField,
} from './input-dynamic-field-values.helper';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MatSelect } from '@angular/material/select';
import { AuthService } from '@core/auth/auth.service';
import { resolveDynamicFieldConditions } from './input-dynamic-field-values-condition.helper';

@UntilDestroy()
@Component({
  selector: 'net-input-dynamic-field-values[mode][entityId]',
  templateUrl: './input-dynamic-field-values.component.html',
  styleUrls: ['./input-dynamic-field-values.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: forwardRef(() => InputDynamicFieldValuesComponent),
    }
  ]
})
export class InputDynamicFieldValuesComponent implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<DynamicFieldValue[]> {
  static nextId = 0;
  pageSize = 100;

  focused = false;
  errorState = false;
  isSubmitted = false;
  controlType = 'input-dynamic-field-values';
  describedBy = '';

  form = new UntypedFormGroup({});
  fields: DynamicField[] = [];
  fieldTypeIds = DynamicFieldTypeId;
  stateChanges = new Subject<void>();

  icLink = icLink;
  icClear = icClear;
  icList = icList;
  icKeyboardArrowUp = icKeyboardArrowUp;
  icKeyboardArrowDown = icKeyboardArrowDown;
  icCircle = icCircle;
  selectedItemsPicklist: DynamicFieldValue[];
  formPatch = {};
  separators: DynamicField[];

  @Input() mode: 'add' | 'edit';
  @Input() columns = 1;
  @Input() isDisabled = false;
  @Input() entityId: DynamicFieldEntityId;
  @Input() entityName: string;
  @Input() uploadType: string;

  @Input() set updateValidations(value: boolean) {
    if (value) {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity({ emitEvent: false });
    } else {
      this.form.markAsUntouched();
    }
  }

  @Input() set openSeparator(openSeparator: boolean) {
    this._openSeparator = openSeparator;
    if (openSeparator) {
      const closedSeparator = this.separators?.filter(sep => !sep.expandFieldFlag) ?? [];
      if (closedSeparator.length > 0) {
        this.separators = this.separators.map(sep => {
          if (sep.separatorFields.filter(field => field.requiredFlag)?.length > 0 && !sep.expandFieldFlag) {
            sep.expandFieldFlag = true;
          }
          return sep;
        });

      }
    }

    this.form.markAllAsTouched();
  }
  get openSeparator(): boolean { return this._openSeparator; }
  private _openSeparator: boolean;

  @Input() set accountStatusId(accountStatusId: string) {
    this._accountStatusId = accountStatusId;
    this.form.updateValueAndValidity();
  }
  get accountStatusId(): string { return this._accountStatusId; }
  private _accountStatusId: string;

  @Input() set opportunityStatusId(opportunityStatusId: string) {
    this._opportunityStatusId = opportunityStatusId;
    this.form.updateValueAndValidity();
  }
  get opportunityStatusId(): string { return this._opportunityStatusId; }
  private _opportunityStatusId: string;

  @Input() set opportunityTypeId(opportunityTypeId: string) {
    this._opportunityTypeId = opportunityTypeId;
    this.form.updateValueAndValidity();
  }
  get opportunityTypeId(): string { return this._opportunityTypeId; }
  private _opportunityTypeId: string;

  @Input() set organizationId(organizationId: string) {
    this._organizationId = organizationId;
    this.form.updateValueAndValidity();
  }
  get organizationId(): string { return this._organizationId; }
  private _organizationId: string;

  @Input() set salesOrganizationId(salesOrganizationId: string) {
    this._salesOrganizationId = salesOrganizationId;
    this.form.updateValueAndValidity();
  }
  get salesOrganizationId(): string { return this._salesOrganizationId; }
  private _salesOrganizationId: string;

  @Input() set serviceIds(serviceIds: string[]) {
    this._serviceIds = serviceIds;
    this.form.updateValueAndValidity();
  }
  get serviceIds(): string[] { return this._serviceIds; }
  private _serviceIds: string[];

  @Input() set assignmentStatusId(assignmentStatusId: string) {
    this._assignmentStatusId = assignmentStatusId;
    this.form.updateValueAndValidity();
  }
  get assignmentStatusId(): string { return this._assignmentStatusId; }
  private _assignmentStatusId: string;

  @Input() set activityStatusId(activityStatusId: string) {
    this._activityStatusId = activityStatusId;
    this.form.updateValueAndValidity();
  }
  get activityStatusId(): string { return this._activityStatusId; }
  private _activityStatusId: string;

  @ViewChild('select') select: MatSelect;
  @HostBinding('id') id = `input-dynamic-field-values-${InputDynamicFieldValuesComponent.nextId++}`;
  @HostBinding('attr.tabindex') tabIndex = -1;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    public authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef,
    private dynamicFieldService: DynamicFieldService,
    private formBuilder: UntypedFormBuilder,
    @Optional() private formGroup: FormGroupDirective,
    @Optional() @Self() public ngControl: NgControl
  ) {

    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }

      this.focused = !!origin;
      this.stateChanges.next();
    });

    // Set ngControl value accessor
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  private _value: DynamicFieldValue[];

  @Input()
  get value(): DynamicFieldValue[] {
    return this._value;
  }

  set value(values: DynamicFieldValue[]) {

    this._value = values;

    // Patch form with values when fields exist
    if (!!values && this.fields.length > 0) {
      this.formPatch = {};
      this.separators = this.getSeparators();
      for (const value of values) {
        const field = this.fields.find(item => item.dynamicFieldId === value?.dynamicFieldId);

        this.formPatch[value.dynamicFieldId] = getDynamicFieldControllerValue(
          this.mode,
          value,
          field
        );
      }


      // this.form.patchValue(formPatch, {emitEvent: false});
    } else if (!!values && values.length > 0 && !this.selectedItemsPicklist) {
      this.selectedItemsPicklist = values.filter(value => value.valueType === 'Picklist');
    }

    this.onChange(values);
    this.stateChanges.next();
  }

  @Input() isAccountHome = false;

  @Input()
  get required(): boolean {
    return false;
  }

  set required(value: boolean) {
    this.stateChanges.next();
  }

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    if (value) {
      this.form.disable();
    } else {
      this.form.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }

  @Input()
  get placeholder(): string {
    return '';
  }

  set placeholder(value: string) {
    this.stateChanges.next();
  }

  @HostBinding('style.--entity-columns') get entityColumn(): number {
    return this.columns;
  }

  get empty() {
    return !this.value;
  }

  get shouldLabelFloat() {
    return true;
  }

  @Output() dynamicFieldSearch = new EventEmitter<DynamicField[]>();

  picklistValuesSearchWithScroll(field: DynamicField) {
    const pageSize = this.pageSize;
    const page = field.currentPage + 1;
    const payload = {
      activeFlag: true,
      page,
      pageSize,
      filter: {
        dynamicFieldId: field.pickListId ?? field.dynamicFieldId,
        ...field.selectedDynamicFieldPicklistValues ? {
          excludeDynamicFieldPickListIds:
            field.selectedDynamicFieldPicklistValues.map(x => x.dynamicFieldPickListId)
        } : {},
        ...field.picklistValuesKeyword ? { name: field.picklistValuesKeyword } : {},
        pagination: true
      }
    };

    field.picklistValuesLoading = true;

    this.dynamicFieldService.picklistPaginationSearch(payload).pipe(
      map(response => response.data),
      map(data => {
        field.picklistValues = field.picklistValues ? [...new Set([...field.picklistValues, ...data.results])] : data.results;
        if (!field.picklistValuesKeyword) {
          field.picklistValuesFiltered = field.picklistValues;
          field.prevCurrentPage = data.currentPage;
          field.prevPageCount = data.pageCount;
          field.prevRowCount = data.rowCount;
        }
        field.currentPage = data.currentPage;
        field.pageCount = data.pageCount;
        field.rowCount = data.rowCount;

        // Update field validity and trigger change detection on next cycle
        setTimeout(() => {
          // Update validity when picklist fetched
          this.form.get(field.dynamicFieldId)?.updateValueAndValidity();

          // Trigger change detection for initiate default values
          this.changeDetectorRef.detectChanges();
        });
        return field.picklistValues;
      })
    ).subscribe(() => {
      field.picklistValuesLoading = false;
    });
  }

  picklistValuesSearchWithKeyword(field: DynamicField) {

    const payload = {
      activeFlag: true,
      page: 1,
      pageSize: this.pageSize,
      filter: {
        dynamicFieldId: field.pickListId ?? field.dynamicFieldId,
        ...field.selectedDynamicFieldPicklistValues ? {
          excludeDynamicFieldPickListIds:
            field.selectedDynamicFieldPicklistValues.map(x => x.dynamicFieldPickListId)
        } : {},
        name: field.picklistValuesKeyword,
        pagination: true
      }
    };

    field.picklistValuesLoading = true;

    this.dynamicFieldService.picklistPaginationSearch(payload).pipe(
      map(response => response.data),
      map(data => {
        field.currentPage = data.currentPage;
        field.pageCount = data.pageCount;
        field.rowCount = data.rowCount;
        const selected = field.selectedDynamicFieldPicklistValues?.filter(x => x.name.toLowerCase().includes(field.picklistValuesKeyword.toLowerCase())) ?? [];
        field.picklistValues = selected ? [...selected, ...data.results] : data.results;

        // Update field validity and trigger change detection on next cycle
        setTimeout(() => {
          // Update validity when picklist fetched
          this.form.get(field.dynamicFieldId).updateValueAndValidity();
          const panel = field.fieldSelectElement?.panel?.nativeElement as HTMLDivElement;
          if (panel) {
            panel.scrollTop = 0;
          }

          // Trigger change detection for initiate default values
          this.changeDetectorRef.detectChanges();
        });
        return field.picklistValues;
      })
    ).subscribe(() => {
      field.picklistValuesLoading = false;
    });
  }

  writeValue(values: []) {
    this.form.reset();
    this.value = values;
    this.form.patchValue(this.formPatch, { emitEvent: true });
    this.changeDetectorRef.detectChanges();
  }

  isDecimalField = (id: string) => isDecimalField(id);

  validateController(): ValidationErrors | null {
    if (this.form.invalid) {
      return { required: true };
    }

    const isRequired = this.fields?.some(field => field.requiredFlag && this.form.get(field.dynamicFieldId).invalid);
    if (isRequired) {
      return { required: true };
    }

    return null;
  }

  checkKey(event) {
    if (event.code === 'Space') {
      event.stopPropagation();
    }
  }

  updateControllerValidity() {
    if (!this.ngControl) {
      return;
    }

    this.ngControl.control.updateValueAndValidity();
  }

  onOpenUrl(field: DynamicField) {
    const existValue = this.value ? this.value.find(item => item?.dynamicFieldId === field.dynamicFieldId) : null;

    if (!existValue) {
      return;
    }
    window.open((existValue.textValue.startsWith('http') ? '' : 'http://').concat(existValue.textValue), '_blank');
  }

  onClearValue(field: DynamicField) {
    const controller = this.form.get(field.dynamicFieldId);

    if (!controller.value) {
      return;
    }

    setTimeout(() => controller.setValue(null));
  }

  onSelectAllPicklistValues(field: DynamicField) {
    if (!field.multipleValueFlag) {
      return;
    }

    if (field.pageCount === field.currentPage) {
      const formControlId = field.dynamicFieldId;
      field.selectedDynamicFieldPicklistValues = [...new Set([...field.selectedDynamicFieldPicklistValues, ...field.picklistValues])].filter(x => x.activeFlag);
      const prevFormValue = this.form.controls[formControlId].value;
      this.form.controls[formControlId].patchValue(
        field.selectedDynamicFieldPicklistValues.map(picklistValues => picklistValues.dynamicFieldPickListId), { emitEvent: true }
      );
      return;
    }
    if (field.picklistValuesKeyword) {
      this.picklistSelectAllRequestWithKeyword(field);
    } else {
      this.picklistSelectAllRequest(field);
    }
  }

  onSelectedPickList(value: string, field: DynamicField) {
    const formControlId = field.dynamicFieldId;

    if (!field.multipleValueFlag) {
      if (field.selectedDynamicFieldPicklistValues[0]?.dynamicFieldPickListId === value) {
        field.selectedDynamicFieldPicklistValues = [];
        this.form.controls[formControlId].patchValue('', { emitEvent: true });
        return;
      }
      field.selectedDynamicFieldPicklistValues = field.picklistValues.filter((item) => item.dynamicFieldPickListId === value);
      this.form.controls[formControlId].patchValue(value, { emitEvent: true });
      return;
    }

    const index = field.selectedDynamicFieldPicklistValues?.findIndex(picklistValue => picklistValue.dynamicFieldPickListId === value) ?? -1;

    if (index === -1) {
      field.selectedDynamicFieldPicklistValues.push(field.picklistValues.find(val => val.dynamicFieldPickListId === value));
    } else {
      field.selectedDynamicFieldPicklistValues.splice(index, 1);
    }


    this.form.controls[formControlId].patchValue(
      field.selectedDynamicFieldPicklistValues.map(picklistValues => picklistValues.dynamicFieldPickListId), { emitEvent: true }
    );
  }

  onDeselectAllPicklistValues(field: DynamicField) {
    if (!field.multipleValueFlag) {
      return;
    }

    if (field.picklistValuesKeyword) {
      field.selectedDynamicFieldPicklistValues = field.selectedDynamicFieldPicklistValues?.filter(picklistValue =>
        !picklistValue.name.toLowerCase().includes(field.picklistValuesKeyword.toLowerCase()));
      this.form.get(field.dynamicFieldId).setValue(
        field.selectedDynamicFieldPicklistValues.map(picklistValue => picklistValue.dynamicFieldPickListId)
      );
    } else {
      field.selectedDynamicFieldPicklistValues = [];
      this.form.get(field.dynamicFieldId).setValue([]);
    }
  }

  onClosedSelectPicklist(field: DynamicField) {
    if (field.picklistValuesKeyword) {
      field.pageCount = field.prevPageCount;
      field.currentPage = field.prevCurrentPage;
      field.rowCount = field.prevRowCount;
    }
    field.picklistValuesKeyword = '';
    field.picklistValuesKeywordFormControl.setValue('', { emitEvent: false });
    field.picklistValues = Object.values(
      [...field.selectedDynamicFieldPicklistValues, ...field.picklistValuesFiltered]
        .reduce((acc, cur) => Object.assign(acc, { [cur.name]: cur }), {}));
  }

  ngOnInit(): void {
    // Fetch dynamic fields for given entity
    this.dynamicFieldService
      .search({
        orderBy: 'orderBy',
        orderType: 'ASC',
        filter: {
          activeFlag: true,
          arrayFlag: false,
          dynamicFieldEntityId: this.entityId,
        }
      })
      .subscribe(response => {
        // Build fields from response
        const fields = response.data.results.map(field => {
          // Fetch picklist's values when type is picklist
          if (DynamicFieldTypeId.PICKLIST === field.dynamicFieldTypeId) {
            field.picklistValuesKeywordFormControl = new UntypedFormControl();
            field.currentPage = 0;
            this.request(field);

            field.picklistValuesKeywordFormControl.valueChanges.pipe(
              debounceTime(400)
            ).subscribe((value: string) => {
              if (value.startsWith(' ')) {
                return;
              }
              field.picklistValuesKeyword = value;
              if (!!value) {
                this.picklistValuesSearchWithKeyword(field);
              } else {
                field.picklistValues = [...field.picklistValuesFiltered];
                field.picklistValues = Object.values(
                  [...field.selectedDynamicFieldPicklistValues, ...field.picklistValuesFiltered]
                    .reduce((acc, cur) => Object.assign(acc, { [cur.name]: cur }), {}));
                field.pageCount = field.prevPageCount;
                field.currentPage = field.prevCurrentPage;
                field.rowCount = field.prevRowCount;
                this.changeDetectorRef.detectChanges();
              }
            });
          }

          return field;
        });

        // Generate form controllers with fields in response
        const controllers = {};
        fields.forEach(field => {
          controllers[field.dynamicFieldId] = [
            getDynamicFieldControllerValue(
              this.mode,
              this.value?.find(item => item?.dynamicFieldId === field.dynamicFieldId) ?? null,
              field
            ),
            getDynamicFieldValidations(field)
          ];
        });

        // Update form with generated fields
        this.form = this.formBuilder.group(controllers, { updateOn: 'change' });

        // Update local field reference
        this.fields = fields;
        this.dynamicFieldSearch.emit(this.fields);

        // Subscribe form value changes to serialize into input value
        this.form.valueChanges.pipe(
          startWith(this.form.value)
        ).subscribe(controls => {
          const values: DynamicFieldValue[] = [];

          this.fields.map(field => {
            field.conditionVisibility = resolveDynamicFieldConditions(field, this.form, fields,
              this.accountStatusId, this.activityStatusId, this.assignmentStatusId,
              this.opportunityStatusId, this.opportunityTypeId, this.organizationId,
              this.salesOrganizationId, this.serviceIds);

            this.getSeparators().map(separator => {
              const sepField = separator.separatorFields?.find(f => f.dynamicFieldId === field.dynamicFieldId);
              if (sepField) {
                field.conditionVisibility = field.conditionVisibility && separator?.conditionVisibility;
              }
            });

            if (field.conditionVisibility) {
              this.form.get(field.dynamicFieldId).setValidators(getDynamicFieldValidations(field));
            } else {
              // DFM - Conditiona bağlı olarak gösterilmeyen alanların arkaplanda tutulan verisi, condition sağlandığında gözükmüyor
              // this.form.get(field.dynamicFieldId).patchValue(null, { emitEvent: false });
              this.form.get(field.dynamicFieldId).clearValidators();
            }
            this.form.get(field.dynamicFieldId).updateValueAndValidity({ emitEvent: false });
            return field;
          });

          // Generate dynamic field value objects from form controls
          Object.keys(controls).forEach(dynamicFieldId => {
            const input = controls[dynamicFieldId];

            // Skip empty inputs
            if (!input) {
              return;
            }
            // Push prepared value to value stack

            const selectedField = this.fields.filter(item => item.conditionVisibility).find(item => item.dynamicFieldId === dynamicFieldId);
            if (!selectedField) {
              return;
            }
            values.push(
              getDynamicFieldValueFromInputByField(
                input,
                selectedField
              )
            );
          });

          // Update input value without empty name values
          this.value = values.filter(item => item?.nameValues ? item?.nameValues?.length > 0 : true);
          this.changeDetectorRef.detectChanges();
        });

        // Trigger change detection
        this.changeDetectorRef.detectChanges();

        setTimeout(() => this.updateControllerValidity());
      });

    // Update from controller validator with local validator
    if (this.ngControl) {
      this.ngControl.control.setValidators(this.validateController.bind(this));
      this.updateControllerValidity();
    }

    // Trigger initial change detection
    this.changeDetectorRef.detectChanges();
  }

  ngDoCheck(): void {
    // Mark sub form is touched when root form is in touched state
    if (!this.isSubmitted && this.formGroup.submitted) {
      this.isSubmitted = true;
      this.form.markAllAsTouched();
      this.stateChanges.next();
      this.changeDetectorRef.markForCheck();
      return;
    }

    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      if (this.errorState !== this.form.invalid) {
        this.errorState = this.form.invalid;
      }

      this.stateChanges.next();
      this.changeDetectorRef.markForCheck();
    }
  }

  createPayload(dynamicFieldId, dynamicFieldPickListIds = null) {
    return {
      activeFlag: true,
      page: 1,
      pageSize: Array.isArray(dynamicFieldPickListIds) ? dynamicFieldPickListIds.length : this.pageSize,
      filter: {
        dynamicFieldId,
        ...Array.isArray(dynamicFieldPickListIds) ? { dynamicFieldPickListIds } :
          dynamicFieldPickListIds ? { dynamicFieldPickListIds: [dynamicFieldPickListIds] } : {},
        pagination: true
      }
    };
  }

  request(field: DynamicField) {
    let payload;
    field.selectedDynamicFieldPicklistValues = [];
    if (field.multipleValueFlag) {
      const selectedPicklistItems = this.selectedItemsPicklist?.find(item => item.dynamicFieldId === field.dynamicFieldId)?.nameValues;
      payload = this.createPayload(
        field.pickListId ?? field.dynamicFieldId,
        selectedPicklistItems?.map(x => x.value)
      );
    } else {
      const selectedPicklistItems = this.selectedItemsPicklist?.find(item => item.dynamicFieldId === field.dynamicFieldId)?.nameValue;
      payload = this.createPayload(
        field.pickListId ?? field.dynamicFieldId,
        selectedPicklistItems?.value
      );
    }

    if (payload?.filter?.dynamicFieldPickListIds) {
      this.getSelectedDynamicFieldPicklistItems(field, payload);
    } else {
      this.picklistValuesSearchWithScroll(field);
    }

  }

  getSelectedDynamicFieldPicklistItems(field: DynamicField, payload) {
    field.picklistValuesLoading = true;

    this.dynamicFieldService.picklistPaginationSearch(payload).pipe(
      map(response => response.data),
      map(data => {
        field.picklistValues = data.results;
        field.selectedDynamicFieldPicklistValues = data.results;
        return field.picklistValues;
      })
    ).subscribe(() => {
      this.picklistValuesSearchWithScroll(field);
    });
  }

  picklistSelectAllRequest(field: DynamicField) {
    const payload = {
      activeFlag: true,
      page: 1,
      pageSize: field.rowCount,
      filter: {
        dynamicFieldId: field.pickListId ?? field.dynamicFieldId,
        ...field.selectedDynamicFieldPicklistValues ? {
          excludeDynamicFieldPickListIds:
            field.selectedDynamicFieldPicklistValues.map(x => x.dynamicFieldPickListId)
        } : {},
        name: field.picklistValuesKeyword,
        pagination: true
      }
    };

    field.picklistValuesLoading = true;

    this.dynamicFieldService.picklistPaginationSearch(payload).pipe(
      map(response => response.data),
      map(data => {
        const formControlId = field.dynamicFieldId;
        field.currentPage = data.currentPage;
        field.pageCount = data.pageCount;
        field.prevPageCount = data.pageCount;
        field.prevCurrentPage = data.currentPage;
        field.prevRowCount = data.rowCount;
        field.picklistValues = [...field.selectedDynamicFieldPicklistValues, ...data.results].filter(x => x.activeFlag);
        field.selectedDynamicFieldPicklistValues = [...field.selectedDynamicFieldPicklistValues, ...data.results].filter(x => x.activeFlag);
        field.picklistValuesFiltered = data.results;
        this.form.controls[formControlId].patchValue(
          field.picklistValues.map(picklistValues => picklistValues.dynamicFieldPickListId), { emitEvent: true }
        );
        setTimeout(() => {
          // Update validity when picklist fetched
          this.form.get(field.dynamicFieldId)?.updateValueAndValidity();

          // Trigger change detection for initiate default values
          this.changeDetectorRef.detectChanges();
        });
        return field;

      })).subscribe(() => {
        field.picklistValuesLoading = false;
      });
  }

  picklistSelectAllRequestWithKeyword(field: DynamicField) {
    const payload = {
      activeFlag: true,
      page: 1,
      pageSize: field.rowCount,
      filter: {
        dynamicFieldId: field.pickListId ?? field.dynamicFieldId,
        ...field.selectedDynamicFieldPicklistValues ? {
          excludeDynamicFieldPickListIds:
            field.selectedDynamicFieldPicklistValues.map(x => x.dynamicFieldPickListId)
        } : {},
        name: field.picklistValuesKeyword,
        pagination: true
      }
    };

    field.picklistValuesLoading = true;

    this.dynamicFieldService.picklistPaginationSearch(payload).pipe(
      map(response => response.data),
      map(data => {
        const formControlId = field.dynamicFieldId;
        field.pageCount = field.prevPageCount;
        field.currentPage = field.prevCurrentPage;
        field.rowCount = field.prevRowCount;
        const selected = field.selectedDynamicFieldPicklistValues?.filter(x => x.name.includes(field.picklistValuesKeyword)).filter(x => x.activeFlag) ?? [];
        if (!field.picklistValuesFiltered) {
          field.picklistValuesFiltered = field.picklistValues.filter(x => x.activeFlag);
        }
        field.picklistValues = selected ? [...selected, ...data.results].filter(x => x.activeFlag) : data.results.filter(x => x.activeFlag);
        if (field.selectedDynamicFieldPicklistValues && field.selectedDynamicFieldPicklistValues.length > 0) {
          field.selectedDynamicFieldPicklistValues = [...field.selectedDynamicFieldPicklistValues, ...data.results].filter(x => x.activeFlag);
          this.form.controls[formControlId].patchValue(
            [...new Set([...field.selectedDynamicFieldPicklistValues, ...field.picklistValues])]
              .map(picklistValues => picklistValues.dynamicFieldPickListId), { emitEvent: true }
          );
        } else {
          field.selectedDynamicFieldPicklistValues = field.picklistValues;
          this.form.controls[formControlId].patchValue(
            field.picklistValues.map(picklistValues => picklistValues.dynamicFieldPickListId), { emitEvent: true }
          );
        }

        setTimeout(() => {
          // Update validity when picklist fetched
          this.form.get(field.dynamicFieldId)?.updateValueAndValidity();

          // Trigger change detection for initiate default values
          this.changeDetectorRef.detectChanges();
        });
        return field;

      })).subscribe(() => {
        field.picklistValuesLoading = false;
      });
  }

  getPicklistValuesFiltered(field: DynamicField): DynamicFieldPicklist[] {
    return field.picklistValues?.filter(value => value.activeFlag ||
      field.selectedDynamicFieldPicklistValues.some(selected => selected.dynamicFieldPickListId === value.dynamicFieldPickListId));
  }

  isDisabledPicklistValues(value: DynamicFieldPicklist, field: DynamicField): boolean {
    return !!field.selectedDynamicFieldPicklistValues.find(selected => selected.dynamicFieldPickListId === value.dynamicFieldPickListId);
  }

  getFormFields() {
    const firstSeparator = this.fields?.find(x => x.dynamicFieldTypeId === DynamicFieldTypeId.SEPARATOR);
    return firstSeparator ? this.fields?.filter(x => x?.orderBy < firstSeparator?.orderBy) : this.fields;
  }

  getSeparators() {
    const separators = this.fields?.filter(x => x.dynamicFieldTypeId === DynamicFieldTypeId.SEPARATOR);
    this.getSeparatorFields(separators);
    return separators;
  }

  getSeparatorFields(separators: DynamicField[]) {
    separators.forEach((field) => {
      const index = this.fields?.findIndex(x => x.dynamicFieldId === field.dynamicFieldId);
      field.separatorFields = [];
      for (let i = index + 1; i < this.fields?.length; i++) {
        if (this.fields[i].dynamicFieldTypeId === DynamicFieldTypeId.SEPARATOR) {
          break;
        }
        field.separatorFields.push(this.fields[i]);
      }

    });
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
  }

  onChange = (_: any) => {
  }

  onTouched = () => {
  }

  onContainerClick = () => {
  }

  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');

}
